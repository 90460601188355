$formSubmit: '.formSubmit';

#{$formSubmit} {
    position: relative;
    display: block;
    width: 100%;

    &__button {
        appearance: none;
    }
}
