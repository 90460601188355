$search: #{'.search'};

#{$search} {
    text-align: right;

    &__link {
        display: block;
        line-height: 55px;

        span {
            display: none;
            color: $color-white;
            font-family: $font-family-bold;
            font-size: $font-size-small;
            line-height: $line-height-prelittle;
            text-transform: uppercase;
        }

        svg {
            position: relative;
            top: 5px;
        }

        svg path {
            fill: $color-white;
            @include transitionDefault;
        }

        &:hover {
            svg path {
                fill: $color-link-water;
            }
        }
    }

    &__formBox {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 11;
        display: none;
    }

    &__form {
        height: 100%;
    }

    &__input {
        height: 100% !important;
        border: none !important;
        background-color: $color-havelock-blue !important;
        padding: 0 35px 0 35px !important;
        color: $color-white !important;
        font-size: $font-size-premedium !important;
        font-family: $font-family-regular !important;

        @include placeholder {
            color: $color-white;
            font-size: $font-size-premedium;
            font-family: $font-family-regular;
        }
    }

    &__btn {
        position: absolute;
        top: 0;
        right: 40px;
        z-index: 13;
        line-height: 55px;

        svg {
            position: relative;
            top: 3px;
        }

        svg path {
            fill: $color-white;
            @include transitionDefault;
        }

        &:hover {
            svg path {
                fill: $color-link-water;
            }
        }
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0px;
        z-index: 13;
        line-height: 55px;

        svg {
            position: relative;
            top: 3px;
        }

        svg polygon {
            fill: $color-white;
            @include transitionDefault;
        }

        &:hover {
            svg polygon {
                fill: $color-link-water;
            }
        }
    }
}
