$header: '.header';

#{$header} {
    position: fixed;
    width: 100%;
    height: map-get($headerHeight, normal);
    @include blockPaddingHorizontal;
    padding-top: 15px;
    padding-left: 0;
    padding-right: 0;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: $color-white;
    @include transitionDefault;

    @include respond-to('increased') {
        height: map-get($headerHeight, tablet);
        // padding-top: 40px;
        // // @include mobileDetect;
        // background-color: transparent;
        // border-bottom: 0;
        // padding-left: 10px;
        // padding-right: 10px;
        -webkit-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.09);
           -moz-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.09);
                box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.09);
    }

    @include respond-to('prebig') {
        height: map-get($headerHeight, mobile);
        top: 0;
        padding-top: 0px;
    }

    &__wrapper {
        position: relative;
        @include container;
        padding-bottom: 15px;

        @include respond-to('huge') {
            padding-left: 10px;
            padding-right: 10px;
        }

        @include respond-to('prebig') {
            padding-top: 10px;
        }
    }

    &.hidden {
        top: -115px;
    }

    .headerBanner {
        @media screen and (max-width: 1140px) {
            display: none;
        }
    }
}



.header.background,
.mobileNavigationShow .header {
    background-color: $color-white;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .15);

    .burger__line {
        background-color: $color-havelock-blue;
    }

    .navigationDesktop__link {
        color: $color-white;
    }
}
