$form: '.form';

#{$form} {
    position: relative;
    display: block;
    @include contentBlock;

    &__wrapper {
        position: relative;
        display: block;
    }

    &__breadcrumbs {
        position: relative;
        display: block;
        margin-bottom: 28px;
    }

    &__header {
        position: relative;
        display: block;
        margin-bottom: 16px;
    }

    &__title {
        position: relative;
        display: block;

        h1 {
            margin-bottom: .4em;
        }
    }

    &__content {
        position: relative;
        display: block;
        @include clearfix;
    }

    &__form {
        @include span(6.4);

        @include respond-to('increased') {
            @include span(12);
        }
    }

    &__item {
        margin-bottom: 17px;

        @include f-last(1) {
            margin-bottom: 0;
        }
    }

    .formRadio {
        .formRadio__list {
            font-size: 0;
        }

        .formRadio__item {
            display: inline-block;
            vertical-align: top;
            margin-right: 50px;
            margin-bottom: 0;

            @include respond-to('increased') {
                display: block;
                margin-bottom: 10px;

                @include f-last(1) {
                    margin-bottom: 0;
                }
            }

            @include f-last(1) {
                margin-right: 0;
            }
        }
    }

    .formSubmit {
        .formSubmit__button {
            padding-left: 53px;
            padding-right: 53px;
        }
    }
}
