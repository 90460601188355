$banner: '.banner';

#{$banner} {
    position: relative;
    display: block;
    @include contentBlock;
    @include blockMargin;
    @include clearfix;
    background-repeat: no-repeat;
    background-position: 96% 100%;
    background-image: url(../images/bg-banner.png);

    @include respond-to('prebig') {
        background-position: 100% 100%;
        background-image: url(../images/bg-banner-mobile.png);
    }

    &__wrapper {
        position: relative;
        display: block;
        @include span(8);

        @include respond-to('increased') {
            @include span(9);
        }

        @include respond-to('prebig') {
            @include span(12);
        }
    }

    &__header {
        position: relative;
        display: block;
    }

    &__title {
        position: relative;
        display: block;

        h1 {
            margin-bottom: .2em;
        }
    }

    &__content {
        position: relative;
        display: block;
    }

    &__text {
        position: relative;
        display: block;
        font-size: $font-size-predefault;
        line-height: $line-height-presmall;

        p {
            @include f-last(1) {
                margin-bottom: 0;
            }
        }
    }
}
