$formErrorField: '.formErrorField';

#{$formErrorField} {
    position: relative;
    display: block;
    width: 100%;
    margin-top: 1px;
    font-family: $font-family-regular;
    font-size: $font-size-smallbefore;
    line-height: $line-height-little;
    color: $color-alizarin-crimson;
}
