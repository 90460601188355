$article: '.article';

#{$article} {
    position: relative;
    display: block;
    @include contentBlock;
    @include blockMargin;
    padding-bottom: 0;

    &__title {
        margin-top: 28px;
        margin-bottom: 25px;
    }

    &__footer {
        padding: 17px 0;
    }
}
