 $ratingTable: '.ratingTable';

#{$ratingTable} {
    position: relative;
    display: block;
    margin-top: 15px;

    @include respond-to('small') {
        &:after {
            position: absolute;
            content: '';
            width: 30px;
            height: 100%;
            top: 0;
            right: 0;
            z-index: 1;
            background-image: -webkit-linear-gradient(left,rgba(255,255,255,0) 0,#fff 80%,#fff 100%);
            background-image: linear-gradient(to right,rgba(255,255,255,0) 0,#fff 80%,#fff 100%);
        }
    }

    &__wrapper {
        overflow-x: auto;
        overflow-y: hidden;
        position: relative;
    }

    &__header {
        display: table;
        width: 100%;
        background-color: #f1f3f5;
        border-top: 1px solid $color-porcelain;
        border-bottom: 1px solid $color-porcelain;

        @include respond-to('small') {
            min-width: 600px;
            padding-right: 20px;
        }

        div {
            font-family: $font-family-regular;
            font-size: $font-size-smaller;
            line-height: $line-height-prelittle;
            color: $color-mine-shaft;
            padding: 13px 0 9px;

            @include respond-to('increased') {
                font-size: $font-size-smallbefore;
            }
        }
    }

    &__content {
        display: block;
        width: 100%;

        @include respond-to('small') {
            min-width: 600px;
            padding-right: 20px;
        }
    }

    &__item {
        display: table;
        border-top: 1px solid $color-porcelain;
        width: 100%;
        padding: 35px 0 40px;
        line-height: $line-height-prelittle;
        @include transitionDefault;

        &:hover {
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.17);
            z-index: 100;
            border-color: transparent;

            & + div {
                border-color: transparent;
            }
        }
    }

    &__number {
        display: table-cell;
        vertical-align: middle;
        width: tablePercentCount(90, 870);
        padding: 0 0 0 28px;

        @include respond-to('increased') {
            padding: 0 0 0 10px;
            width: tablePercentCount(80, 870);
        }
    }

    &__company {
        display: table-cell;
        vertical-align: middle;
        width: tablePercentCount(200, 870);

        @include respond-to('increased') {
            width: tablePercentCount(230, 870);
        }

        a {
            font-family: $font-family-semibold;
            font-size: $font-size-premedium;
            color: $color-havelock-blue;
            display: block;
            @include transitionDefault;

            @include respond-to('increased') {
                font-size: $font-size-small;
            }

            &:hover {
                color: $color-sun;
            }
        }
    }

    &__rating {
        display: table-cell;
        vertical-align: middle;
        width: tablePercentCount(150, 870);

        @include respond-to('increased') {
            width: tablePercentCount(180, 870);
        }
    }

    &__rate {
        display: table-cell;
        vertical-align: middle;
        width: tablePercentCount(110, 870);
    }

    &__ok {
        display: table-cell;
        vertical-align: middle;
        width: tablePercentCount(130, 870);
    }

    &__resolve {
        display: table-cell;
        vertical-align: middle;
        width: tablePercentCount(90, 870);
    }

    &__leader {
        display: table-cell;
        vertical-align: middle;
        width: tablePercentCount(100, 870);

        @include respond-to('increased') {
            width: tablePercentCount(50, 870);
        }
    }

    &__valueNumber {
        font-family: $font-family-regular;
        font-size: $font-size-predefault;
        color: $color-mine-shaft;
        margin-bottom: 4px;
    }

    &__positive {
        font-family: $font-family-regular;
        font-size: $font-size-smaller;
        color: $color-japanese-laurel;
    }

    &__negative {
        font-family: $font-family-regular;
        font-size: $font-size-smaller;
        color: $color-alizarin-crimson;
    }

    &__views {
        padding-top: 2px;

        svg path {
            fill: #bec4cc;
        }

        span {
            font-family: $font-family-regular;
            font-size: $font-size-smaller;
            color: $color-mine-shaft;
            margin-left: 8px;
        }
    }

    &__rating {
        margin: 0;

        .stars {
            display: block;
            margin-top: 0px;
            font-size: 0;
            line-height: 1em;
        }

        .stars__list {
            margin: 0;
        }

        .stars__right {
            p {
                line-height: 1em;
            }
        }
    }

    &__reviews {
        font-family: $font-family-regular;
        font-size: $font-size-smaller;
        color: $color-mine-shaft;
        margin-top: 5px;

        span {
            opacity: 0.5;
        }
    }

    &__valueRate {
        font-family: $font-family-regular;
        font-size: $font-size-predefault;
        color: $color-mine-shaft;
    }

    &__periodRate {
        font-family: $font-family-regular;
        font-size: $font-size-smaller;
        color: $color-mine-shaft;
        opacity: 0.5;
        margin-top: 4px;
    }

    &__valueOk {
        font-family: $font-family-regular;
        font-size: $font-size-predefault;
        color: $color-mine-shaft;
    }

    &__valueResolve {
        font-family: $font-family-regular;
        font-size: $font-size-predefault;
        color: $color-mine-shaft;
    }

    &__leaderBox {
        text-align: center;

        span {
            font-family: $font-family-semibold;
            font-size: $font-size-predefault;
            color: $color-sun;
            display: inline-block;
            vertical-align: middle;
            margin-left: 4px;

            @include respond-to('increased') {
                display: none;
            }
        }

        svg {
            display: inline-block;
            vertical-align: middle;
        }

        svg path {
            fill: $color-sun;
        }
    }

    &__footer {
        padding: 17px 40px 15px;
        border-top: 1px solid $color-porcelain;
    }
}
