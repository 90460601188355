$text: '.text';

#{$text} {
    position: relative;
    display: block;
    @include blockMargin;
    @include contentBlock;
    background-color: $color-white;

    &__wrapper {
        position: relative;
        display: block;
    }

    &__content {
        position: relative;
        display: block;
    }

    &__text {
        position: relative;
        display: block;
        font-size: $font-size-predefault;
        line-height: $line-height-large;

        p {
            margin-bottom: .9em;

            @include f-last(1) {
                margin-bottom: 0;
            }
        }
    }

    &__footer {
        padding-top: 30px;
    }
}
