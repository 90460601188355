$formSuccessState: '.formSuccessState';

#{$formSuccessState} {
    .formInput__wrapper {
        &:after {
            content: '';
            position: absolute;
            display: block;
            width: 10px;
            height: 8px;
            top: 50%;
            right: 13px;
            margin-top: -4px;
            background-repeat: no-repeat;
            background-position: 0 0;
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjEzcHgiIHZpZXdCb3g9IjAgMCAxNiAxMyIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTYgMTMiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiNmY2IzMTUiIGQ9Ik0xNS4xMTYsMC4zMTNjLTAuNjA4LTAuNDkxLTEuNTAxLTAuMzk0LTEuOTkzLDAuMjE2TDYuNDk0LDguNzY3TDIuNjksNS41NEMyLjA5Myw1LjAzNCwxLjE5OSw1LjEwOCwwLjY5Miw1LjcwNEMwLjE4Niw2LjMwMSwwLjI1OSw3LjE5NSwwLjg1Niw3LjcwMmw0LjkxMyw0LjE2OGMwLjAwNSwwLjAwNSwwLjAxMiwwLjAwNiwwLjAxNywwLjAxczAuMDA3LDAuMDEsMC4wMTIsMC4wMTRjMC4wODYsMC4wNjksMC4xODUsMC4xMDgsMC4yNzksMC4xNTVjMC4wNDcsMC4wMjIsMC4wODksMC4wNTksMC4xMzgsMC4wNzVjMC4xNTIsMC4wNTQsMC4zMTIsMC4wODIsMC40NzEsMC4wODJjMC4xMDMsMCwwLjIwNC0wLjAxMiwwLjMwNC0wLjAzM2MwLjAyOS0wLjAwNywwLjA1OC0wLjAyMSwwLjA4Ni0wLjAyOGMwLjA3LTAuMDIsMC4xNC0wLjAzOSwwLjIwNS0wLjA2OWMwLjAzNS0wLjAxNywwLjA2Ni0wLjA0LDAuMS0wLjA2YzAuMDU3LTAuMDMxLDAuMTE0LTAuMDYyLDAuMTY3LTAuMTAyYzAuMDM1LTAuMDI3LDAuMDY1LTAuMDYyLDAuMDk4LTAuMDkzYzAuMDQyLTAuMDM4LDAuMDg2LTAuMDcxLDAuMTIzLTAuMTE1YzAuMDAzLTAuMDA0LDAuMDAzLTAuMDA4LDAuMDA3LTAuMDEyYzAuMDA1LTAuMDA2LDAuMDEyLTAuMDEsMC4wMTctMC4wMTdsNy41NDEtOS4zNzFDMTUuODIyLDEuNjk2LDE1LjcyNSwwLjgwNSwxNS4xMTYsMC4zMTN6Ii8+PC9zdmc+);
            background-size: 10px 8px;
            z-index: 2;
        }
    }

    .formInput__input {
        padding-right: 36px;
    }
}
