$sort: '.sort';

#{$sort} {
    position: relative;
    display: block;

    &__wrapper {
        position: relative;
        display: block;
        font-size: 0;
        @include clearfix;
        white-space: nowrap;
    }

    &__title {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        font-family: $font-family-regular;
        font-size: $font-size-smaller;
        line-height: $line-height-prelittle;
        color: $color-mine-shaft;
    }

    &__list {
        position: relative;
        display: inline-block;
        vertical-align: middle;

        ul {
            @include clearfix;
        }

        ul,
        ul li {
            @include list-reset;
        }

        ul {
            li {
                position: relative;
                display: inline-block;
                vertical-align: middle;

                a {
                    position: relative;
                    display: block;
                    padding-top: 3px;
                    padding-bottom: 3px;
                    padding-left: 10px;
                    padding-right: 10px;
                    font-family: $font-family-regular;
                    font-size: $font-size-smaller;
                    line-height: $line-height-prelittle;
                    border-radius: 3px;
                    @include transitionDefault;
                }

                a.active {
                    color: $color-white;
                    background-color: $color-sun;
                }
            }
        }
    }
}
