$rotator: '.rotator';

#{$rotator} {
    position: relative;
    display: block;
    width: 100%;
    @include sideBarBlock;
    @include blockMargin;

    &__content {
        margin-top: 13px;
        margin-left: -16px;
        margin-right: -16px;
        position: relative;

        .newsItem__content {
            margin: 0 15px;
        }
    }

    &__title {
        h2 {
            letter-spacing: 0px;
        }
    }

    &__btn {
        margin-top: 10px;

        a {
            font-size: $font-size-predefault;
            color: $color-neon-carrot;

            &:hover {
                color: $color-havelock-blue;
            }
        }
    }

    &__arrow {
        position: absolute;
        top: -40px;
        right: 15px;
        font-size: 0;

        svg polygon {
            fill: $color-havelock-blue;
            transition: all .2s ease-in-out;
        }
    }

    &__next {
        display: inline-block;
        width: 12px;
        height: 21px;

        &:hover {
            cursor: pointer;

            svg polygon {
                fill: $color-sun;
            }
        }
    }

    &__prev {
        display: inline-block;
        width: 12px;
        height: 21px;
        margin-right: 35px;
        &:hover {
            cursor: pointer;

            svg polygon {
                fill: $color-sun;
            }
        }
    }
}
