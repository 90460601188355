$articleSingle: '.articleSingle';

#{$articleSingle} {
    position: relative;
    display: block;
    @include contentBlock;
    @include blockMargin;

    &__wrapper {
        position: relative;
        display: block;
    }

    &__breadcrumbs {
        position: relative;
        display: block;
        margin-bottom: 28px;
    }

    &__header {
        position: relative;
        display: block;
    }

    &__title {
        position: relative;
        display: block;
        margin-bottom: 30px;
        padding-top: 200px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;

        @include respond-to('prebig') {
            margin-left: -20px;
            margin-right: -20px;
            padding-top: 100px;
            padding-left: 10px;
            padding-right: 10px;
        }

        h1 {
            margin-bottom: .2em;
            font-family: $font-family-bold;
            font-size: $font-size-semilarge/1.0666;
            line-height: $line-height-little;
            color: $color-white;

            @include respond-to('prebig') {
                font-size: $font-size-largeafter;
            }
        }
    }

    &__meta {
        position: relative;
        display: block;
        padding-left: 2px;
        @include clearfix;
    }

    &__date {
        position: relative;
        float: left;
        margin-right: 20px;
        font-family: $font-family-regular;
        font-size: $font-size-smaller;
        color: $color-white;
    }

    &__social {
        position: relative;
        font-size: 0;
    }

    &__views {
        display: inline-block;
        color: $color-white;
        margin-right: 10px;

        svg {
            height: 9px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 2px;
        }

        svg path {
            fill: $color-white;
        }

        span {
            display: inline-block;
            font-size: $font-size-smaller;
            vertical-align: middle;
        }
    }

    &__like {
        display: inline-block;
        color: $color-white;

        svg {
            height: 12px;
            display: inline-block;
            vertical-align: middle;
            margin-top: 2px;
            margin-right: 2px;
        }

        svg path {
            fill: $color-sun;
        }

        span {
            display: inline-block;
            font-size: $font-size-smaller;
            vertical-align: middle;
        }
    }

    &__subtitle {
        position: relative;
        display: block;
        margin-bottom: 23px;
        font-family: $font-family-semibold;
        font-size: $font-size-medium;
        line-height: $line-height-small;

        @include respond-to('prebig') {
            font-size: $font-size-premedium;
        }

        p {
            @include f-last(1) {
                margin-bottom: 0;
            }
        }
    }

    &__content {
        position: relative;
        display: block;
        margin-bottom: 21px;
        font-size: $font-size-predefault;
        line-height: $line-height-large;

        h1 {
            margin-top: 1.7em;
            margin-bottom: .7em;

            @include f-first(1) {
                margin-top: 0;
            }
        }

        h2 {
            margin-top: 1.7em;
            margin-bottom: .7em;

            @include f-first(1) {
                margin-top: 0;
            }
        }

        h3 {
            margin-top: 1.7em;
            margin-bottom: .7em;

            @include f-first(1) {
                margin-top: 0;
            }
        }

        h4 {
            margin-top: 1.7em;
            margin-bottom: .7em;

            @include f-first(1) {
                margin-top: 0;
            }
        }

        h5 {
            margin-top: 1.7em;
            margin-bottom: .7em;

            @include f-first(1) {
                margin-top: 0;
            }
        }

        h6 {
            margin-top: 1.7em;
            margin-bottom: .7em;

            @include f-first(1) {
                margin-top: 0;
            }
        }

        p {
            margin-bottom: 1em;

            @include f-last(1) {
                margin-bottom: 0;
            }
        }

        img {
            margin-top: 2.2em;
            margin-bottom: 2.2em;

            @include f-last(1) {
                margin-bottom: 0;
            }
        }

        ul {
            @include clearfix;
        }

        ul,
        ul li {
            @include list-reset;
        }

        ul {
            margin-bottom: 1em;
            margin-left: 1.3em;

            @include f-last(1) {
                margin-bottom: 0;
            }

            li {
                position: relative;
                margin-bottom: 1em;
                padding-left: 1.3em;

                &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 10px;
                    height: 8px;
                    top: 8px;
                    left: 0;
                    background-repeat: no-repeat;
                    background-position: 0 0;
                    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjEzcHgiIHZpZXdCb3g9IjAgMCAxNiAxMyIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTYgMTMiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiM0Yjg3ZDIiIGQ9Ik0xNS4xMTYsMC4zMTNjLTAuNjA4LTAuNDkxLTEuNTAxLTAuMzk0LTEuOTkzLDAuMjE2TDYuNDk0LDguNzY3TDIuNjksNS41NEMyLjA5Myw1LjAzNCwxLjE5OSw1LjEwOCwwLjY5Miw1LjcwNEMwLjE4Niw2LjMwMSwwLjI1OSw3LjE5NSwwLjg1Niw3LjcwMmw0LjkxMyw0LjE2OGMwLjAwNSwwLjAwNSwwLjAxMiwwLjAwNiwwLjAxNywwLjAxczAuMDA3LDAuMDEsMC4wMTIsMC4wMTRjMC4wODYsMC4wNjksMC4xODUsMC4xMDgsMC4yNzksMC4xNTVjMC4wNDcsMC4wMjIsMC4wODksMC4wNTksMC4xMzgsMC4wNzVjMC4xNTIsMC4wNTQsMC4zMTIsMC4wODIsMC40NzEsMC4wODJjMC4xMDMsMCwwLjIwNC0wLjAxMiwwLjMwNC0wLjAzM2MwLjAyOS0wLjAwNywwLjA1OC0wLjAyMSwwLjA4Ni0wLjAyOGMwLjA3LTAuMDIsMC4xNC0wLjAzOSwwLjIwNS0wLjA2OWMwLjAzNS0wLjAxNywwLjA2Ni0wLjA0LDAuMS0wLjA2YzAuMDU3LTAuMDMxLDAuMTE0LTAuMDYyLDAuMTY3LTAuMTAyYzAuMDM1LTAuMDI3LDAuMDY1LTAuMDYyLDAuMDk4LTAuMDkzYzAuMDQyLTAuMDM4LDAuMDg2LTAuMDcxLDAuMTIzLTAuMTE1YzAuMDAzLTAuMDA0LDAuMDAzLTAuMDA4LDAuMDA3LTAuMDEyYzAuMDA1LTAuMDA2LDAuMDEyLTAuMDEsMC4wMTctMC4wMTdsNy41NDEtOS4zNzFDMTUuODIyLDEuNjk2LDE1LjcyNSwwLjgwNSwxNS4xMTYsMC4zMTN6Ii8+PC9zdmc+);
                    background-size: 10px 8px;
                }

                @include f-last(1) {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__quote {
        position: relative;
        display: block;
        margin-top: 1.4em;
        margin-bottom: 1.3em;
        padding-top: 6px;
        padding-bottom: 8px;
        padding-left: 20px;
        font-family: $font-family-semibold;
        font-size: $font-size-medium;
        line-height: $line-height-small;
        border-left: 3px solid $color-havelock-blue;

        @include respond-to('prebig') {
            font-size: $font-size-default;
        }
    }

    &__footer {
        position: relative;
        display: block;
    }

    &__author {
        position: relative;
        display: block;
        margin-bottom: 40px;
        font-family: $font-family-italic;
        font-size: $font-size-small;
        line-height: $line-height-prelittle;
        color: $color-silver-chalice;
    }
}
