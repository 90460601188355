$stars: '.stars';

#{$stars} {
    @include clearfix;

    &__left {
        width: 85px;
        float: left;
    }

    &__right {
        float: left;

        p {
            margin: 0 0 0 0;
            color: $color-sun;
            font-size: $font-size-smaller;
            line-height: 19px;
        }
    }

    &__list {
        @include list-reset;
        font-size: 0;
        margin-top: 3px;
    }

    &__item {
        display: inline-block;
        margin-right: 3px;

        svg {
            margin: 0;
            padding: 0;
        }

        svg path {
            fill: $color-athens-gray-light;
        }
    }

    &.stars__hover {
        &__1 {
            #{$stars} {
                &__item {
                    @include f-first(1) {
                        svg path {
                            fill: $color-navajo-white;
                        }
                    }

                    @include f-last(4) {
                        svg path {
                            fill: $color-athens-gray-light;
                        }
                    }
                }
            }
        }

        &__2 {
            #{$stars} {
                &__item {
                    @include f-first(2) {
                        svg path {
                            fill: $color-navajo-white;
                        }
                    }

                    @include f-last(3) {
                        svg path {
                            fill: $color-athens-gray-light;
                        }
                    }
                }
            }
        }

        &__3 {
            #{$stars} {
                &__item {
                    @include f-first(3) {
                        svg path {
                            fill: $color-navajo-white;
                        }
                    }

                    @include f-last(2) {
                        svg path {
                            fill: $color-athens-gray-light;
                        }
                    }
                }
            }
        }

        &__4 {
            #{$stars} {
                &__item {
                    @include f-first(4) {
                        svg path {
                            fill: $color-navajo-white;
                        }
                    }

                    @include f-last(1) {
                        svg path {
                            fill: $color-athens-gray-light;
                        }
                    }
                }
            }
        }

        &__5 {
            #{$stars} {
                &__item {
                    @include f-first(5) {
                        svg path {
                            fill: $color-navajo-white;
                        }
                    }
                }
            }
        }
    }

    &.stars {
        &__1 {
            #{$stars} {
                &__item {
                    @include f-first(1) {
                        svg path {
                            fill: $color-sun;
                        }
                    }
                }
            }
        }

        &__2 {
            #{$stars} {
                &__item {
                    @include f-first(2) {
                        svg path {
                            fill: $color-sun;
                        }
                    }
                }
            }
        }

        &__3 {
            #{$stars} {
                &__item {
                    @include f-first(3) {
                        svg path {
                            fill: $color-sun;
                        }
                    }
                }
            }
        }

        &__4 {
            #{$stars} {
                &__item {
                    @include f-first(4) {
                        svg path {
                            fill: $color-sun;
                        }
                    }
                }
            }
        }

        &__5 {
            #{$stars} {
                &__item {
                    @include f-first(5) {
                        svg path {
                            fill: $color-sun;
                        }
                    }
                }
            }
        }
    }
}

.js_touch {
    #{$stars} {
        &.stars__hover {
            &__1 {
                #{$stars} {
                    &__item {
                        @include f-first(1) {
                            svg path {
                                fill: $color-athens-gray-light;
                            }
                        }

                        @include f-last(4) {
                            svg path {
                                fill: $color-athens-gray-light;
                            }
                        }
                    }
                }
            }

            &__2 {
                #{$stars} {
                    &__item {
                        @include f-first(2) {
                            svg path {
                                fill: $color-athens-gray-light;
                            }
                        }

                        @include f-last(3) {
                            svg path {
                                fill: $color-athens-gray-light;
                            }
                        }
                    }
                }
            }

            &__3 {
                #{$stars} {
                    &__item {
                        @include f-first(3) {
                            svg path {
                                fill: $color-athens-gray-light;
                            }
                        }

                        @include f-last(2) {
                            svg path {
                                fill: $color-athens-gray-light;
                            }
                        }
                    }
                }
            }

            &__4 {
                #{$stars} {
                    &__item {
                        @include f-first(4) {
                            svg path {
                                fill: $color-athens-gray-light;
                            }
                        }

                        @include f-last(1) {
                            svg path {
                                fill: $color-athens-gray-light;
                            }
                        }
                    }
                }
            }

            &__5 {
                #{$stars} {
                    &__item {
                        @include f-first(5) {
                            svg path {
                                fill: $color-athens-gray-light;
                            }
                        }
                    }
                }
            }
        }

        &.stars {
            &__1 {
                #{$stars} {
                    &__item {
                        @include f-first(1) {
                            svg path {
                                fill: $color-sun;
                            }
                        }
                    }
                }
            }

            &__2 {
                #{$stars} {
                    &__item {
                        @include f-first(2) {
                            svg path {
                                fill: $color-sun;
                            }
                        }
                    }
                }
            }

            &__3 {
                #{$stars} {
                    &__item {
                        @include f-first(3) {
                            svg path {
                                fill: $color-sun;
                            }
                        }
                    }
                }
            }

            &__4 {
                #{$stars} {
                    &__item {
                        @include f-first(4) {
                            svg path {
                                fill: $color-sun;
                            }
                        }
                    }
                }
            }

            &__5 {
                #{$stars} {
                    &__item {
                        @include f-first(5) {
                            svg path {
                                fill: $color-sun;
                            }
                        }
                    }
                }
            }
        }
    }
}
