$headerBannerTablet: '.headerBannerTablet';

#{$headerBannerTablet} {
    text-align: center;
    margin-bottom: 10px;
    display: none;

    @media screen and (max-width: 1140px) {
        display: block;
    }

    .headerBanner {
        float: none;
    }

    .headerBanner__item {
        border: none;
        @include sideBarBlock;
        padding-left: 120px;
    }
}
