$logo: '.logo';

#{$logo} {
    position: relative;
    width: 210px;
    height: 46px;
    float: left;
    margin-top: 20px;

    @include respond-to('increased') {
        height: 40px;
        margin-top: 10px;
    }

    @include respond-to('prebig') {
        height: 40px;
        margin-top: 0;
    }

    img {
        margin: 0;

        @include respond-to('increased') {
            height: 100%;
        }
    }

    // .logo-path-1 {
    //     fill: $color-white;
    //     transition: all .2s ease;

    //     @include respond-to('increased') {
    //         fill: $color-white;
    //     }
    // }

    // .logo-path-2 {
    //     fill: $color-white;
    //     transition: all .2s ease;

    //     @include respond-to('increased') {
    //         fill: $color-white;
    //     }
    // }
}
