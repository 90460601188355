$newsItem: '.newsItem';

#{$newsItem} {
    overflow: hidden;

    &__header {
        position: relative;
        overflow: hidden;
    }

    &__image {
        overflow: hidden;
        background-size: cover;
        transition: .2s;
        position: relative;

        &:after {
            position: absolute;
            content: '';
            width: 100%;
            height: 30px;
            display: block;
            bottom: 0px;
            left: 0;
            background: rgba(0,0,0,0);
            background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,0.6)));
            background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
            background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
            background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
        }

        img {
            width: 100%;
            margin: 0;
        }
    }

    &__date {
        position: absolute;
        left: 10px;
        bottom: 7px;
        color: $color-white;
        font-size: $font-size-smaller;
        font-family: $font-family-regular;

        @include respond-to('big') {
            font-size: $font-size-extrasmall;
        }

        @include respond-to('prebig') {
            font-size: $font-size-smaller;
        }
    }

    &__social {
        position: absolute;
        right: 10px;
        bottom: 7px;
        font-size: 0;
    }

    &__views {
        display: inline-block;
        color: $color-white;
        margin-right: 10px;

        @include respond-to('big') {
            margin-right: 5px;
        }

        @include respond-to('prebig') {
            margin-right: 10px;
        }

        svg {
            height: 9px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 2px;
        }

        svg path {
            fill: $color-white;
        }

        span {
            display: inline-block;
            font-size: $font-size-smaller;
            vertical-align: middle;
        }
    }

    &__like {
        display: inline-block;
        color: $color-white;

        svg {
            height: 12px;
            display: inline-block;
            vertical-align: middle;
            margin-top: 2px;
            margin-right: 2px;
        }

        svg path {
            fill: $color-sun;
        }

        span {
            display: inline-block;
            font-size: $font-size-smaller;
            vertical-align: middle;
        }
    }

    &__link {
        display: block;

        &:hover {
            #{$newsItem} {
                &__image {
                    transform: scale(1.05);
                }

                &__title {
                    color: $color-sun;
                }
            }
        }
    }

    &__title {
        color: $color-havelock-blue;
        font-size: $font-size-predefault;
        font-family: $font-family-bold;
        line-height: $line-height-presmall;
        transition: all .2s ease-in-out;
        margin-top: 12px;
    }

    &__text {
        color: $color-mine-shaft;
        font-size: $font-size-predefault;
        font-family: $font-family-regular;
        line-height: $line-height-presmall;
        margin-top: 7px;
    }

    &__arrow {
        position: absolute;
        top: 50%;
        margin-top: -12.5px;
        right: 40px;
        content: '';
        display: block;
        width: 12px;
        height: 21px;
        transition: all .2s ease-in-out;

        @include respond-to('prebig') {
            display: none;
        }

        svg polygon {
            transition: all .2s ease-in-out;
            fill: $color-silver-dark;
        }
    }

    &:hover {
        #{$newsItem} {
            &__arrow {
                right: 35px;

                svg polygon {
                    fill: $color-sun;
                }
            }
        }
    }
}
