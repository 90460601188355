$sidebar: '.sidebar';

#{$sidebar} {
    position: relative;
    display: block;
    margin-left: -18px;

    @include respond-to('increased') {
        margin-left: -10px;
    }

    @include respond-to('prebig') {
        margin-left: 0;
    }
}
