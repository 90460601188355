$main: '.main';

#{$main} {
    position: relative;
    display: block;
    padding-top: 25px;
    padding-bottom: 40px;
    @include blockPaddingHorizontal;
    padding-top: map-get($headerHeight, normal);
    margin-top: 25px;

    @include respond-to('increased') {
       padding-top: map-get($headerHeight, tablet);
    }

    @include respond-to('prebig') {
       padding-top: map-get($headerHeight, mobile);
       margin-top: 20px;
    }

    &__wrapper {
        position: relative;
        display: block;
        @include container;
        @include clearfix;
    }

    &__col {
        position: relative;
        display: block;
        float: left;
    }

    &__col.content {
        @include span(9);

        @include respond-to('increased') {
            @include span(8);
        }

        @include respond-to('prebig') {
            @include span(12);
            margin-bottom: 15px;

            .seoText {
                display: none;
            }
        }

        section {
            @include f-last(1) {
                margin-bottom: 0;
            }
        }
    }

    &__col.sidebar {
        @include span(3 last);

        .seoText {
            display: none;
        }

        @include respond-to('increased') {
            @include span(4 last);
        }

        @include respond-to('prebig') {
            @include span(12);

            .seoText {
                display: block;
            }
        }

        section {
            @include f-last(1) {
                margin-bottom: 0;
            }
        }
    }

    &.mainSubNavigation {
        padding-top: map-get($headerHeight, normal) + 42px;

        @include respond-to('increased') {
            padding-top: map-get($headerHeight, tablet);
        }

        @include respond-to('prebig') {
            padding-top: map-get($headerHeight, mobile);
        }
    }
}
