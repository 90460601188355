/* opensans */
$fonts_dir: '../fonts/opensans';

/* bold */
@font-face {
    font-family: 'open_sansbold';
    src: url('#{$fonts_dir}/bold/opensans-bold.eot');
    src: url('#{$fonts_dir}/bold/opensans-bold.eot?#iefix') format('embedded-opentype'),
    url('#{$fonts_dir}/bold/opensans-bold.svg#open_sansbold') format('svg'),
    url('#{$fonts_dir}/bold/opensans-bold.woff') format('woff'),
    url('#{$fonts_dir}/bold/opensans-bold.woff2') format('woff'),
    url('#{$fonts_dir}/bold/opensans-bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* italic */
@font-face {
    font-family: 'open_sansitalic';
    src: url('#{$fonts_dir}/italic/opensans-italic.eot');
    src: url('#{$fonts_dir}/italic/opensans-italic.eot?#iefix') format('embedded-opentype'),
    url('#{$fonts_dir}/italic/opensans-italic.svg#open_sansitalic') format('svg'),
    url('#{$fonts_dir}/italic/opensans-italic.woff') format('woff'),
    url('#{$fonts_dir}/italic/opensans-italic.woff2') format('woff'),
    url('#{$fonts_dir}/italic/opensans-italic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* light */
@font-face {
    font-family: 'open_sanslight';
    src: url('#{$fonts_dir}/light/opensans-light.eot');
    src: url('#{$fonts_dir}/light/opensans-light.eot?#iefix') format('embedded-opentype'),
    url('#{$fonts_dir}/light/opensans-light.svg#open_sanslight') format('svg'),
    url('#{$fonts_dir}/light/opensans-light.woff') format('woff'),
    url('#{$fonts_dir}/light/opensans-light.woff2') format('woff'),
    url('#{$fonts_dir}/light/opensans-light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* regular */
@font-face {
    font-family: 'open_sansregular';
    src: url('#{$fonts_dir}/regular/opensans-regular.eot');
    src: url('#{$fonts_dir}/regular/opensans-regular.eot?#iefix') format('embedded-opentype'),
    url('#{$fonts_dir}/regular/opensans-regular.svg#open_sansregular') format('svg'),
    url('#{$fonts_dir}/regular/opensans-regular.woff') format('woff'),
    url('#{$fonts_dir}/regular/opensans-regular.woff2') format('woff'),
    url('#{$fonts_dir}/regular/opensans-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* semibold */
@font-face {
    font-family: 'open_sanssemibold';
    src: url('#{$fonts_dir}/semibold/opensans-semibold.eot');
    src: url('#{$fonts_dir}/semibold/opensans-semibold.eot?#iefix') format('embedded-opentype'),
    url('#{$fonts_dir}/semibold/opensans-semibold.svg#open_sanssemibold') format('svg'),
    url('#{$fonts_dir}/semibold/opensans-semibold.woff') format('woff'),
    url('#{$fonts_dir}/semibold/opensans-semibold.woff2') format('woff'),
    url('#{$fonts_dir}/semibold/opensans-semibold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
