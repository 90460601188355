$formEnter: '.formEnter';

#{$formEnter} {
    position: relative;
    display: block;

    &__wrapper {
        position: relative;
        display: block;
    }

    &__header {
        position: relative;
        display: block;
        margin-bottom: 15px;
    }

    &__title {
        position: relative;
        display: block;
        font-size: $font-size-predefault;
        line-height: $line-height-large;

        p {
            @include f-last(1) {
                margin-bottom: 0;
            }
        }
    }

    &__content {
        position: relative;
        display: block;
        padding-bottom: 15px;
        border-bottom: 1px solid $color-athens-gray-lighter;
    }

    &__list {
        @include clearfix;
    }

    &__list,
    &__item {
        @include list-reset;
    }

    &__item {
        position: relative;
        display: block;
        float: left;
        margin-right: 15px;
        margin-bottom: 15px;
        @include clearfix;

        @include f-last(1) {
            margin-right: 0;
        }
    }

    &__link {
        position: relative;
        display: block;
        float: left;
        min-width: 180px;
        height: 40px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 0;
        line-height: 40px;
        background-color: $color-havelock-blue;
        border-radius: 5px;
        white-space: nowrap;
        @include transitionDefault;
    }

    &__link.vk {
        background-color: $color-steel-blue;

        &:hover {
            background-color: lighten($color-steel-blue, 5%);
        }

        &:active {
            background-color: darken($color-steel-blue, 8%);
        }
    }

    &__link.fb {
        background-color: $color-east-bay;

        &:hover {
            background-color: lighten($color-east-bay, 5%);
        }

        &:active {
            background-color: darken($color-east-bay, 8%);
        }
    }

    &__link.ok {
        background-color: $color-tahiti-gold;

        &:hover {
            background-color: lighten($color-tahiti-gold, 5%);
        }

        &:active {
            background-color: darken($color-tahiti-gold, 8%);
        }
    }

    &__icon {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;

        svg {
            position: relative;
            display: block;

            path {
                fill: $color-white;
            }
        }
    }

    &__text {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        max-width: 150px;
        font-family: $font-family-semibold;
        font-size: $font-size-predefault;
        line-height: $line-height-prelittle;
        color: $color-white;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__footer {
        position: relative;
        display: block;
        padding-top: 20px;
    }

    &__subtitle {
        position: relative;
        display: block;
        font-size: $font-size-predefault;
        line-height: $line-height-large;

        p {
            @include f-last(1) {
                margin-bottom: 0;
            }
        }
    }
}
