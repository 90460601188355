$historyData: '.historyData';

#{$historyData} {
    position: relative;
    display: block;
    margin-left: -40px;
    margin-right: -40px;

    @include respond-to('prebig') {
        margin-left: -20px;
        margin-right: -20px;
    }

    &__wrapper {
        position: relative;
        display: block;
    }

    &__header {
        position: relative;
        display: block;
    }

    &__sort {
        background-color: $color-porcelain;
        font-size: 0;
        padding: 8px 18px 7px;
        border-top: 1px solid $color-porcelain;
        @include clearfix;

        .sort {
            display: inline-block;
            vertical-align: middle;
            float: right;

            @include respond-to('small') {
                float: none;
                display: block;

                .sort__title {
                    display: block;
                    margin-top: 5px;
                }
                .sort__list {
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }
        }
    }

    &__sortName {
        display: inline-block;
        font-size: $font-size-smaller;
        vertical-align: middle;
    }

    &__content {
        position: relative;
        display: block;
        @include clearfix;
    }

    &__item {
        position: relative;
        display: table;
        width: 100%;
        border-top: 1px solid $color-porcelain;
        @include clearfix;
        @include transitionDefault;

        &:hover {
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.17);
            z-index: 100;
            border-color: transparent;

            & + .historyData__item {
                border-color: transparent;
            }
        }

        @include respond-to('increased') {
            display: block;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    &__row {
        position: relative;
        display: table-row;

        @include respond-to('increased') {
            display: block;
        }
    }

    &__col {
        position: relative;
        display: table-cell;
        vertical-align: middle;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-right: 10px;

        @include respond-to('increased') {
            display: block;
            width: auto !important;
        }
    }

    &__col.col_preview {
        width: tablePercentCount(150, 870);

        @include respond-to('increased') {
            float: left;
        }

        @include respond-to('medium') {
            width: 40% !important;
        }
    }

    &__col.col_title {
        width: tablePercentCount(260, 870);

        @include respond-to('increased') {
            max-width: 200px;
            float: left;
            clear: right;
        }

        @include respond-to('prebig') {
            max-width: 250px;
        }

        @include respond-to('medium') {
            width: 60% !important;
            max-width: none;
        }
    }

    &__col.col_sum {
        width: tablePercentCount(90, 870);

        @include respond-to('increased') {
            float: left;
            clear: left;
        }
    }

    &__col.col_time {
        width: tablePercentCount(170, 870);

        @include respond-to('increased') {
            float: left;
        }
    }

    &__col.col_cta {
        width: tablePercentCount(200, 870);
        padding-right: 20px;

        @include respond-to('increased') {
            padding-right: 0;
        }

        @include respond-to('medium') {
            clear: both;
        }
    }

    &__preview {
        position: relative;
        display: block;
        margin-bottom: 5px;

        img {
            max-height: 56px;
            margin-bottom: 0;

            @include respond-to('prebig') {
                margin-left: 0;
            }
        }
    }

    &__stars {
        text-align: center;

        @include respond-to('prebig') {
            text-align: left;
        }

        .stars {
            display: inline-block;
        }
    }

    &__title {
        position: relative;
        display: block;
        margin-bottom: .5em;
        font-family: $font-family-semibold;
        font-size: $font-size-premedium;
        line-height: $line-height-prelittle;
    }

    &__payment {
        position: relative;
        display: block;

        @include respond-to('small') {
            display: block;
        }

        svg {
            position: relative;
            display: inline-block;
            vertical-align: top;
            margin-right: 10px;
        }

        svg path {
            fill: $color-silver;
        }
    }

    &__sum {
        position: relative;
        display: block;
        font-size: $font-size-predefault;
        line-height: $line-height-little;

        span {
            position: relative;
            padding-right: 20px;

            &:after {
                content: '';
                position: absolute;
                display: block;
                width: 20px;
                height: 20px;
                top: 1px;
                right: 0;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA0NDAgNDQwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NDAgNDQwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZmlsbD0iIzMzMzMzMyIgZD0iTTIzMi41MjIsMjQyLjQyOGM2My45MTMsMCwxMTUuOTEtNTQuMzgyLDExNS45MS0xMjEuMjI3QzM0OC40MzIsNTQuMzcsMjk2LjQzNSwwLDIzMi41MjIsMEgxMjAuNTY4djI4Mi40MjhoLTI5djMwaDI5VjQ0MGgzMFYzMTIuNDI4aDEwMS45NTV2LTMwSDE1MC41Njh2LTQwSDIzMi41MjJ6IE0xNTAuNTY4LDMwaDgxLjk1NWM0Ny4zNzEsMCw4NS45MSw0MC45MTIsODUuOTEsOTEuMjAxYzAsNTAuMzAzLTM4LjUzOSw5MS4yMjctODUuOTEsOTEuMjI3aC04MS45NTVWMzB6Ii8+PC9zdmc+);
                background-size: 12px 12px;
            }
        }
    }

    &__label {
        position: relative;
        display: block;
        font-size: $font-size-smaller;
        line-height: $line-height-prelittle;
        opacity: .5;
    }

    &__cta {
        position: relative;
        display: block;
        text-align: right;

        @include respond-to('medium') {
            text-align: left;
        }

        a {
            @include respond-to('medium') {
                padding-left: 60px;
                padding-right: 60px;
            }
        }
    }
}
