$navigationMobile: #{'.navigationMobile'};

#{$navigationMobile} {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: $color-white;
    z-index: 999;
    overflow-y: scroll;
    text-align: center;

    &__wrapper {
        position: relative;
        width: 100%;
        padding-top: map-get($headerHeight, tablet);

        @include respond-to('prebig') {
            padding-top: map-get($headerHeight, mobile);
        }
    }

    &__content {
        position: relative;
        display: block;
        text-align: left;
    }

    &__list,
    &__item {
        @include list-reset;
    }

    &__list {
        ul,
        ul li {
            @include list-reset;
        }
    }

    &__item {
        ul {
            display: none;
            transition: all .2s ease;
            text-align: center;

            li {

                a {
                    position: relative;
                    display: block;
                    padding: 0;
                    font-family: $font-family-regular;
                    font-size: $font-size-small;
                    color: $color-neon-carrot;
                    background-color: $color-white;
                    line-height: 50px;
                    border-bottom: 1px solid $color-athens-gray-lighter;
                }
            }
        }
    }

    &__link {
        position: relative;
        display: block;
        padding-top: 18px;
        padding-bottom: 16px;
        @include blockPaddingHorizontal;
        font-family: $font-family-bold;
        font-size: $font-size-small;
        color: $color-white;
        line-height: $line-height-prelittle;
        background-color: $color-havelock-blue;
        text-align: center;
        border-bottom: 1px solid #4682cd;
        text-transform: uppercase;
        border-top: 1px solid #5490db;

        &:hover {
            color: $color-white;
        }
    }

    &__item.dropdown {
        #{$navigationMobile} {
            &__link {
                &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 0;
                    height: 0;
                    right: 24px;
                    top: 50%;
                    margin-top: -2px;
                    border-style: solid;
                    border-width: 4px 4px 0 4px;
                    border-color: $color-white transparent transparent transparent;
                    @include transitionDefault;
                }
            }
        }
    }

    &__item {
        @include f-first(1) {
            #{$navigationMobile} {
                &__link {
                    border-top: none;
                }
            }
        }
    }

    &__item.active {
        ul {
            display: block;

        }

        #{$navigationMobile} {
            &__link {
                color: $color-white;
                background-color: $color-mariner-blue;

                &:hover {
                    background-color: $color-mariner-blue;
                }

                &:active {
                    background-color: $color-mariner-blue;
                }

                &:after {
                    border-color: $color-white transparent transparent transparent;
                    transform: rotate(-180deg);
                }
            }
        }
    }

    &__active {
        color: $color-mariner-blue !important;
    }

    &__search {

    }
}

