$btn: '.btn';

#{$btn} {
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 16px;
    padding-right: 16px;
    width: auto;
    font-family: $font-family-regular;
    font-size: $font-size-small;
    line-height: $line-height-prelittle;
    color: $color-white;
    text-decoration: none;
    outline: none;
    text-align: center;
    background-color: $color-sun;
    border-radius: 2px;
    cursor: pointer;
    @include transitionDefault;

    &:hover {
        color: $color-white;
        text-decoration: none;
        background-color: $color-sunglow;
    }

    &:active {
        color: $color-white;
        text-decoration: none;
        background-color: $color-yellow-sea;
    }
}

#{$btn}.btn_red {
    background-color: $color-alizarin-crimson;
    border-radius: 4px 4px 0 0;
    font-size: $font-size-predefault;
    padding-left: 87px;
    padding-right: 87px;
    padding-top: 13px;
    padding-bottom: 13px;

    &:hover {
        color: $color-white;
        text-decoration: none;
        background-color: $color-crimson;
    }

    &:active {
        color: $color-white;
        text-decoration: none;
        background-color: $color-thunderbird;
    }
}

#{$btn}.btn_blue {
    background-color: $color-havelock-blue;

    &:hover {
        color: $color-white;
        text-decoration: none;
        background-color: $color-cornflower-blue;
    }

    &:active {
        color: $color-white;
        text-decoration: none;
        background-color: $color-mariner;
    }
}

#{$btn}.btn_calc {
    border-bottom: 3px solid $color-mariner-dark;

    &:hover {
        border-bottom: 3px solid $color-mariner-light;
    }

    &:active {
        border-bottom: 3px solid transparent;
    }
}

#{$btn}.btn_large {
    padding-top: 13px;
    padding-bottom: 13px;
    font-size: $font-size-premedium;
    font-family: $font-family-semibold;
}

#{$btn}.disabled {
    background-color: $color-athens-gray-light;
    color: $color-white;
}
