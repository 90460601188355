$subNavigation: #{'.subNavigation'};

#{$subNavigation} {
    padding: 7px 0px;
    width: 100%;
    background-color: $color-mariner-blue;
    display: block;
    text-align: center;
    border-top: 1px solid #275da1;
    position: relative;

    @include respond-to('increased') {
        display: none;
    }

    &:after {
        position: absolute;
        content: '';
        left: 0;
        top: 0px;
        width: 100%;
        height: 1px;
        display: block;
        background-color: #2c69b7;
        z-index: 4;
    }

    &__wrapper {
        position: relative;
        @include container;
    }

    ul {
        margin: 0;
        padding: 0;
        font-size: 0;

        li {
            display: inline-block;

            a {
                font-size: $font-size-smaller;
                font-family: $font-family-regular;
                line-height: $line-height-prelittle;
                color: $color-perano;
                padding: 7px 30px 7px;
                display: block;
                @include transitionDefault;

                &.navigationDesktop__active {
                    background-color: $color-sun;
                    color: $color-black !important;
                    border-radius: 27px;
                }

                &:hover {
                    color: $color-white;
                }
            }
        }
    }
}

