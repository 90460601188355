$tabs: '.tabs';

#{$tabs} {
    position: relative;
    display: block;
    @include contentBlock;
    @include blockMargin;
    padding: 0;

    @include respond-to('prebig') {
        padding: 0;
    }

    &__list {
        display: table;
        width: 100%;
        border-radius: 2px 2px 0 0;
        overflow: hidden;
    }

    &__item {
        display: table-cell;
        width: 25%;
        text-align: center;
        position: relative;

        @include respond-to('small') {
            width: 50%;
            display: inline-block;
        }

        &:before {
            position: absolute;
            top: 50%;
            left: 0;
            content: '';
            width: 1px;
            height: 21px;
            margin-top: -11.5px;
            background-color: $color-athens-gray-dark;
        }

        @include f-first(1) {
            &:before {
                display: none;
            }
        }

        &.active {
            &:before {
                display: none;
            }

            a {
                color: $color-white;
                background-color: $color-havelock-blue;
                border-bottom: 1px solid $color-havelock-blue;
            }
        }
    }

    &__item.active + &__item {
        &:before {
            display: none;
        }
    }

    &__link {
        font-size: $font-size-small;
        font-family: $font-family-bold;
        color: $color-blue-bayoux;
        line-height: $line-height-prelittle;
        text-transform: uppercase;
        padding: 20px 0 19px;
        width: 100%;
        display: block;
        box-sizing: border-box;
        border-bottom: 1px solid $color-mystic;
        @include transitionDefault;
        background-color: $color-athens-gray;

        @include respond-to('intermediary') {
            font-size: $font-size-extrasmall;
        }

        @include respond-to('small') {
            font-size: $font-size-small;
        }
    }

    &__content {
        padding: 0 40px;

        @include respond-to('prebig') {
            padding: 0 3px;
            height: auto;
        }

        .newsOne {
            .newsItem {
                margin-bottom: 30px;
            }
        }

        .newsColumns {
            padding-top: 30px;
            border-top: 1px solid $color-athens-gray-lighter;

            .newsItem {
                margin-bottom: 20px;
            }
        }
    }

    &__contentList {
        @include respond-to('prebig') {
            display: none;
        }
    }

    &__contentListMobile {
        display: none;
        margin: 0 0px;

        .rotator {
            border: none;
            box-shadow: none;
            margin-bottom: 0;
        }

        @include respond-to('prebig') {
            display: block;
        }
    }

    &__title {
        margin-top: 20px;
        margin-bottom: 15px;
    }

    &__footer {
        padding-bottom: 25px;

        a {
            color: $color-sun;
            @include transitionDefault;

            &:hover {
                color: $color-havelock-blue;
            }
        }
    }
}
