$ad: '.ad';

#{$ad} {
    position: relative;
    display: block;
    width: 100%;
    @include sideBarBlock;
    @include blockMargin;
    padding-top: 25px;

    &__image {
        img {
            margin: 0 auto;
        }
    }
}
