$formCheckbox: '.formCheckbox';

#{$formCheckbox} {
    position: relative;
    display: block;
    width: 100%;

    &__input {
        position: absolute;
        left: -9999px;
    }

    &__label {
        position: relative;
        display: block;
        width: 100%;
        min-height: 20px;
        padding-left: 29px;
        padding-top: 1px;
        padding-right: 15px;
        cursor: pointer;
        @include transitionDefault;

        &:hover {
            #{$formCheckbox} {
                &__icon {
                    border-color: $color-sun;
                }
            }
        }
    }

    &__icon {
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        border: 2px solid $color-mystic;
        border-radius: 3px;
        cursor: pointer;
        @include transitionDefault;

        svg {
            position: absolute;
            display: block;
            width: 10px;
            height: 8px;
            top: 50%;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            margin-top: -4px;
            opacity: 0;
            @include transitionDefault;

            path {
                fill: $color-sun;
            }
        }
    }

    &__input:checked + &__label {
        #{$formCheckbox} {
            &__icon {
                border-color: $color-sun;

                svg {
                    opacity: 1;
                }
            }
        }
    }

    &__text {
        font-family: $font-family-regular;
        font-size: $font-size-predefault;
        line-height: $line-height-little;
        color: $color-black;
        text-transform: none;
    }
}

#{$formCheckbox}.disabled {
    #{$formCheckbox} {
        &__label {
            cursor: default;
        }

        &__icon {
            border-color: $color-mystic !important;
            cursor: default;
            opacity: .6;

            svg {
                path {
                    fill: $color-mystic !important;
                }
            }
        }

        &__text {
            color: $color-mystic !important;
            opacity: .6;
        }
    }
}
