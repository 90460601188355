$newsFeed: '.newsFeed';

#{$newsFeed} {
    position: relative;
    display: block;
    @include blockMargin;
    @include contentBlock;
    padding-bottom: 0;

    &__wrapper {
        position: relative;
        display: block;
    }

    &__header {
        position: relative;
        display: block;
    }

    &__title {
        position: relative;
        display: block;

        h2 {
            @include blockTitle;
        }
    }

    &__content {
        position: relative;
        display: block;
    }

    &__list {
        @include clearfix;
    }

    &__list,
    &__item {
        @include list-reset;
    }

    &__item {
        position: relative;
        display: block;
        margin-bottom: 20px;
    }

    &__date {
        position: relative;
        display: block;
        margin-bottom: .5em;
        font-size: $font-size-smaller;
        line-height: $line-height-prelittle;
        color: rgba($color-black, .5);
    }

    &__link {
        position: relative;
        display: block;
        @include clearfix;

        a {
            position: relative;
            display: block;
            float: left;
            font-family: $font-family-semibold;
            font-size: $font-size-predefault;
            line-height: $line-height-little;
            @include transitionDefault;
        }
    }

    &__hidden {
        position: relative;
        display: none;
        overflow: hidden;
    }

    &__footer {
        position: relative;
        display: block;
        margin-left: -40px;
        margin-right: -40px;
        text-align: center;
        border-top: 1px solid $color-athens-gray-dark;

        @include respond-to('prebig') {
            margin-left: -20px;
            margin-right: -20px;
        }
    }

    &__trigger {
        position: relative;
        display: inline-block;
        vertical-align: top;
        padding-top: 12px;
        padding-bottom: 20px;
        font-size: $font-size-predefault;
        line-height: $line-height-prelittle;

        span {
            border-bottom: 1px dashed $color-neon-carrot;
        }

        &:hover {
            color: $color-havelock-blue;

            span {
                border-color: $color-havelock-blue;
            }
        }
    }
}
