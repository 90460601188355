$compare: '.compare';
$blockHeight: 41px;

#{$compare} {
    position: fixed;
    display: block;
    width: 284px;
    right: 17%;
    bottom: -$blockHeight;
    height: $blockHeight;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    overflow: hidden;
    box-shadow: 0 1px 0 0 rgba($color-black, 0.1);
    @include transitionDefault;
    z-index: 999;

    @include respond-to('prebig') {
        position: relative;
        display: none;
        width: 100%;
        right: auto;
        @include blockMargin;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    &__wrapper {
        position: relative;
        display: block;
        width: 568px;
        font-size: 0;
        @include clearfix;
        @include transitionDefault;
        white-space: nowrap;

        @include respond-to('prebig') {
            width: 100%;
        }
    }

    &__col {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 284px;
        height: $blockHeight;
        white-space: normal;

        @include respond-to('prebig') {
            width: 100%;
        }
    }

    &__col.col_1 {
        padding-right: 47px;
        background-color: $color-sun;
    }

    &__col.col_2 {
        background-color: #fff;
    }

    &__title {
        position: relative;
        display: block;
        width: 100%;
        height: $blockHeight;
        font-size: $font-size-predefault;
        color: $color-black;
        line-height: $blockHeight;
        text-align: center;
        background-color: $color-sun;
        @include transitionDefault;

        &:hover {
            color: $color-black;
            background-color: $color-sunglow-light;
        }

        &:active {
            background-color: $color-yellow-sea;
        }
    }

    &__trigger {
        position: absolute;
        display: block;
        width: 47px;
        height: 41px;
        top: 0;
        right: 0;
        background-color: $color-sun;
        border-left: 1px solid $color-gamboge;
        @include transitionDefault;

        &:hover {
            background-color: $color-sunglow-light;
        }

        &:active {
            background-color: $color-yellow-sea;
        }

        svg {
            position: absolute;
            display: block;
            width: 14px;
            height: 18px;
            top: 50%;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            margin-top: -9px;

            path {
                fill: #556b80;
            }
        }
    }

    &__delete {
        position: relative;
        display: block;
        width: 100%;
        height: $blockHeight;
        font-size: $font-size-predefault;
        line-height: $blockHeight;
        color: $color-white;
        text-align: center;
        background-color: $color-alizarin-crimson;
        @include transitionDefault;

        &:hover {
            color: $color-white;
            background-color: $color-crimson;
        }

        &:active {
            background-color: $color-thunderbird;
        }
    }
}

#{$compare}.active {
    bottom: 0;

    @include respond-to('prebig') {
        display: block;
    }
}

#{$compare}.delete {
    #{$compare} {
        &__wrapper {
            margin-left: -284px;

            @include respond-to('prebig') {
                margin-left: -100%;
            }
        }
    }
}
