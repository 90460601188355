$formTextarea: '.formTextarea';

#{$formTextarea} {
    position: relative;
    display: block;
    width: 100%;

    &__wrapper {
        position: relative;
        display: block;
        width: 100%;
    }

    &__textarea {
        z-index: 1;
    }

    &__label {
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: .3em;
        font-family: $font-family-bold;
        font-size: $font-size-predefault;
        line-height: $line-height-little;
        color: $color-cod-gray;

        span {
            font-family: $font-family-regular;
            font-size: $font-size-smaller;
            color: $color-silver;
        }
    }
}

#{$formTextarea}.formTextarea_disabled {
    cursor: default;
    opacity: .4;

    &:after {
        content: '';
        position: absolute;
        display: block;
        top: -2px;
        bottom: -2px;
        left: -2px;
        right: -2px;
        z-index: 5;
    }

    #{$formTextarea} {
        &__textarea {
            color: rgba($color-cod-gray, .8);
            border-color: $color-mystic;
            background-color: $color-white;
            pointer-events: none;
        }
    }
}
