$articleSingleArticles: '.articleSingleArticles';

#{$articleSingleArticles} {
    position: relative;
    display: block;
    @include contentBlock;
    @include blockMargin;

    .newsOne {
        .newsItem {
            margin-bottom: 30px;
        }
    }

    .newsColumns {
        padding-top: 30px;
        border-top: 1px solid $color-athens-gray-lighter;

        .newsItem {
            margin-bottom: 20px;
        }
    }


    &__title {
        margin-top: 0px;
        margin-bottom: 15px;
    }

    &__footer {
        padding-bottom: 0px;

        a {
            color: $color-sun;
            @include transitionDefault;

            &:hover {
                color: $color-havelock-blue;
            }
        }
    }
}
