$headerComparison: '.headerComparison';

#{$headerComparison} {
    float: right;
    padding: 0 0px;
    line-height: 85px;
    margin-right: 30px;

    @include respond-to('increased') {
        display: none;
    }

    &__link {
        font-size: 0;
        display: block;

        svg {
            display: inline-block;
            vertical-align: middle;
        }

        svg path {
            fill: $color-neon-carrot;
            @include transitionDefault;
        }

        span {
            display: inline-block;
            vertical-align: middle;
            font-size: $font-size-small;
            color: $color-neon-carrot;
            margin-left: 7px;
            @include transitionDefault;
        }

        &:hover {
            svg path {
                fill: $color-havelock-blue;
            }

            span {
                color: $color-havelock-blue;
            }
        }
    }
}

.mobileComparison {
    @include respond-to('increased') {
        display: block;
        float: none;
        width: 100%;
        line-height: 50px;
        text-align: center;
        background-color: $color-white;
    }
}
