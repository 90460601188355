$headerBanner: '.headerBanner';

#{$headerBanner} {
    float: left;
    padding: 0 20px 0 50px;
    font-size: 0;

    @media screen and (max-width: 1140px) {
        padding: 0;
    }

    img {
        margin: 0 0 0px 0;
    }

    &__link {
        display: inline-block;

        @media screen and (max-width: 1140px) {
            margin-bottom: 10px;
        }

        @include f-last(1) {
            @media screen and (max-width: 1140px) {
                margin-bottom: 0px;
            }
        }
    }

    &__item {
        border: 1px solid $color-silver;
        display: inline-block;
        background-color: $color-white;
        background-repeat: no-repeat;
        padding-left: 120px;
        padding-right: 20px;
        padding-top: 15px;
        padding-bottom: 15px;
        max-width: 300px;
        background-position: 15px 50%;
        height: 85px;
        margin-left: 20px;
        text-align: left;
        @include transitionDefault;

        &:hover {
            -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow:    0px 3px 10px 0px rgba(0, 0, 0, 0.2);
            box-shadow:         0px 3px 10px 0px rgba(0, 0, 0, 0.2);
        }

        @include respond-to('medium') {
            margin-left: 0;
        }
    }

    &__title {
        font-size: $font-size-predefault;
        font-family: $font-family-semibold;
        line-height: $line-height-prelittle;
        color: $color-mine-shaft;
    }

    &__text {
        font-size: $font-size-smaller;
        font-family: $font-family-regular;
        line-height: $line-height-little;
        color: $color-mine-shaft;
        margin-top: 5px;
    }
}
