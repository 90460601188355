$reviews: '.reviews';

#{$reviews} {
    position: relative;
    display: block;
    @include contentBlock;

    &__wrapper {
        position: relative;
        display: block;
    }

    &__header {
        position: relative;
        display: block;
        padding-bottom: 13px;
    }

    &__title {
        position: relative;
        display: block;
        @include clearfix;

        h2 {
            @include blockTitle;
            margin-right: 28px;
            margin-bottom: 0;
            float: left;

            @include respond-to('prebig') {
                float: none;
                margin-bottom: .5em;
            }
        }
    }

    &__btn {
        @include respond-to('increased') {
            float: right;
        }

        @include respond-to('prebig') {
            float: none;
        }
    }

    &__btn.active {
        background-color: $color-mariner-blue;
        box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.19);
    }

    &__btn.focus {
        background-color: $color-mariner-blue;

        &:hover {
            background-color: $color-mariner-blue;
        }
    }

    &__sort {
        position: relative;
        display: block;
        float: right;
        padding-top: 6px;

        @include respond-to('increased') {
            float: left;
        }

        @include respond-to('prebig') {
            float: none;
        }

        .sort {
            margin-right: -10px;

            @include respond-to('increased') {
                margin-right: 0;
                padding-top: 20px;
            }
        }
    }

    &__content {
        position: relative;
        display: block;
    }

    &__form {
        position: relative;
        display: none;
        margin-left: -40px;
        margin-right: -40px;
        padding-top: 24px;
        padding-bottom: 29px;
        padding-left: 40px;
        padding-right: 40px;
        background-color: $color-athens-gray;
        border-top: 1px solid $color-porcelain;
        @include clearfix;

        @include respond-to('prebig') {
            margin-left: -20px;
            margin-right: -20px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    &__col {
        position: relative;
        display: block;
    }

    &__col.col_1 {
        @include span(7.5);

        @include respond-to('intermediary') {
            @include span(12);
            margin-bottom: 17px;
        }
    }

    &__col.col_2 {
        @include span(4 last);

        @include respond-to('intermediary') {
            @include span(12);
        }
    }

    &__col.col_3 {
        @include span(12);
    }

    &__info {
        position: relative;
        display: block;
        padding-top: 4px;
        font-family: $font-family-regular;
        font-size: $font-size-smaller;
        line-height: $line-height-small;
        color: $color-mine-shaft;
        opacity: .5;

        p {
            @include f-last(1) {
                margin-bottom: 0;
            }
        }
    }

    &__submit {
        position: relative;
        display: block;
        padding-top: 26px;

        .formSubmit__button {
            padding-left: 53px;
            padding-right: 53px;
        }
    }

    &__item {
        margin-bottom: 17px;

        @include f-last(1) {
            margin-bottom: 0;
        }
    }

    &__triangle {
        position: absolute;
        display: block;
        width: 14px;
        height: 8px;
        top: -8px;
        left: 41%;

        @include respond-to('increased') {
            display: none;
        }

        &:before {
            content: '';
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            top: 0;
            left: 0;
            border-style: solid;
            border-width: 0 7px 8px 7px;
            border-color: transparent transparent $color-porcelain transparent;
        }

        &:after {
            content: '';
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            top: 1px;
            left: 0;
            margin-left: 1px;
            border-style: solid;
            border-width: 0 6px 7px 6px;
            border-color: transparent transparent $color-athens-gray transparent;
        }
    }

    &__list {
        position: relative;
        @include clearfix;
    }

    &__list,
    &__child {
        @include list-reset;
    }

    &__child {
        position: relative;
        display: block;
        margin-bottom: 18px;

        @include f-last(1) {
            margin-bottom: 0;
        }
    }

    &__head {
        position: relative;
        display: block;
        @include clearfix;
    }

    &__name {
        position: relative;
        display: block;
        float: left;
        margin-right: 14px;
        margin-bottom: .3em;
        font-family: $font-family-semibold;
        font-size: $font-size-predefault;
        line-height: $line-height-presmall;
        color: $color-havelock-blue;
    }

    &__text {
        position: relative;
        display: block;
        margin-bottom: 8px;
        font-family: $font-family-italic;
        font-size: $font-size-predefault;
        line-height: $line-height-presmall;
        color: $color-black;

        p {
            @include f-last(1) {
                margin-bottom: 0;
            }
        }
    }

    &__profit {
        position: relative;
        display: block;
        font-size: 0;
        white-space: nowrap;
    }

    &__profit__title {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-right: 6px;
        font-family: $font-family-regular;
        font-size: $font-size-smaller;
        line-height: $line-height-prelittle;
        color: $color-mine-shaft;
    }

    &__profit__count {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-right: 13px;
        font-family: $font-family-regular;
        font-size: $font-size-smaller;
        line-height: $line-height-prelittle;
        color: $color-mine-shaft;
    }

    &__profit__number {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
    }

    &__profit__number.positive {
        color: $color-japanese-laurel;
    }

    &__profit__number.negative {
        color: $color-red;
    }

    &__profit__action {
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }

    &__profit__link {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-right: 9px;
        font-family: $font-family-regular;
        font-size: $font-size-smaller;
        line-height: $line-height-prelittle;
        color: $color-mine-shaft;
        border-bottom: 1px dashed $color-mine-shaft;
    }

    &__profit__link.positive {
        color: $color-japanese-laurel;
        border-color: $color-japanese-laurel;
    }

    &__profit__link.negative {
        color: $color-red;
        border-color: $color-red;
    }

    &__footer {
        position: relative;
        display: block;
        margin-top: 28px;
        margin-left: -40px;
        margin-right: -40px;
        margin-bottom: -30px;
        text-align: center;
        border-top: 1px solid $color-athens-gray-dark;

        @include respond-to('prebig') {
            margin-left: -20px;
            margin-right: -20px;
        }
    }

    &__trigger {
        position: relative;
        display: inline-block;
        vertical-align: top;
        padding-top: 12px;
        padding-bottom: 20px;
        font-size: $font-size-predefault;
        line-height: $line-height-prelittle;

        span {
            border-bottom: 1px dashed $color-neon-carrot;

            i {
                font-style: normal;
            }
        }

        &:hover {
            color: $color-havelock-blue;

            span {
                border-color: $color-havelock-blue;
            }
        }
    }

    &__stars {
        position: relative;
        display: block;
        float: left;
        margin-bottom: .3em;
    }
}

#{$reviews}.hasForm {
    #{$reviews} {
        &__list {
            padding-top: 24px;

            &:before {
                content: '';
                position: absolute;
                display: block;
                height: 1px;
                left: -40px;
                right: -40px;
                top: 0;
                background-color: $color-athens-gray-dark;

                @include respond-to('prebig') {
                    left: -20px;
                    right: -20px;
                }
            }
        }
    }
}
