$social: '.social';

#{$social} {
    position: relative;
    display: block;
    text-align: left;

    &__header {
        position: relative;
        display: block;
    }

    &__title {
        position: relative;
        display: block;
        margin-bottom: .5em;
        font-size: $font-size-smaller;
        line-height: $line-height-little;
        color: $color-white;
        opacity: .5;
    }

    &__content {
        position: relative;
        display: block;
    }

    &__list {
        @include clearfix;
        font-size: 0;
    }

    &__list,
    &__item {
        @include list-reset;
    }

    &__item {
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;

        @include f-last(1) {
            margin-right: 0;
        }
    }

    &__link {
        position: relative;
        display: block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: $color-white;
        border-radius: 3px;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
        @include transitionDefault;

        &:hover {
            background-color: rgba($color-white, .8);
        }

        svg {
            position: relative;
            display: inline-block;
            vertical-align: middle;
        }
    }
}
