 $ratingPage: '.ratingPage';

#{$ratingPage} {
    position: relative;
    display: block;
    @include contentBlock;
    @include blockMargin;
    padding-bottom: 0;

    &__wrapper {
        position: relative;
        display: block;
    }

    &__breadcrumbs {
        position: relative;
        display: block;
        margin-bottom: 13px;
    }

    &__header {
        position: relative;
        display: block;
        margin-bottom: 24px;
    }

    &__title {
        position: relative;
        display: block;

        h1 {
            margin-bottom: .2em;
        }
    }

    &__content {
        position: relative;
        display: block;
    }

    &__ratingTable {
        margin: 0 -40px;

        @include respond-to('prebig') {
            margin: 0 -20px;
        }
    }
}
