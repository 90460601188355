$newsOne: '.newsOne';
$newsColumns: '.newsColumns';
$newsList: '.newsList';

#{$newsOne} {
    .newsItem {
        margin-bottom: 40px;

        &__header {
            @include span(6.2);

            @include respond-to('preincreased') {
                @include span(12);
            }

        }

        &__content {
            @include span(5.8 last);

            @include respond-to('preincreased') {
                @include span(12 last);
            }
        }

        &__title {
            margin-top: 7px;

            @include respond-to('preincreased') {
                margin-top: 12px;
            }
        }
    }
}


#{$newsColumns} {
    @include clearfix;

    .newsItem {
        @include gallery(4 of 12);
        margin-bottom: 35px;

        @include respond-to('preincreased') {
            @include gallery(6 of 12);
        }

        @include respond-to('medium') {
            @include gallery(12 of 12);
            clear: both !important;
        }
    }
}


#{$newsList} {
    border-top: 1px solid $color-porcelain;
    margin: 0 -40px;

    @include respond-to('prebig') {
        margin: 0 -20px;
    }

    .newsItem {
        box-sizing: border-box;
        padding: 20px 40px;
        padding-right: 100px;
        border-bottom: 1px solid $color-porcelain;
        position: relative;

        @include respond-to('prebig') {
            padding: 20px;
        }

        &__title {
            font-family: $font-family-regular;
            margin: 5px 0 0 0;
        }

        &__date {
            position: static;
            color: $color-silver-dark;
        }

        &__text {
            margin-top: 5px;
        }
    }
}
