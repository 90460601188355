html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

body {
    font-weight: 400;
    font-family: $font-family-regular;
    font-size: $font-size-predefault;
    line-height: $line-height-presmall;
    color: $color-mine-shaft;
    -webkit-font-smoothing: antialiased;
}

p,
img,
iframe {
    margin-bottom: 1.3em;
}

img,
iframe {
    position: relative;
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

h1 {
    font-weight: normal;
    font-family: $font-family-regular;
    color: $color-mine-shaft;
}

h2 {
    font-weight: normal;
    font-family: $font-family-regular;
    color: $color-mine-shaft;
}

h3 {
    font-weight: normal;
    font-family: $font-family-semibold;
    color: $color-mine-shaft;
}

h4,
h5,
h6 {
    font-weight: normal;
    font-family: $font-family-bold;
    color: $color-mine-shaft;
}

h1 {
    font-size: $font-size-largeplus;
    line-height: $line-height-prelittle;

    @include respond-to('increased') {
        font-size: $font-size-largemedium;
    }
}

h2 {
    font-size: $font-size-largeafter;
    line-height: $line-height-little;

    @include respond-to('increased') {
        font-size: $font-size-prelarge;
    }
}

h3 {
    font-size: $font-size-medium;
    line-height: $line-height-small;
}

h4 {
    font-size: $font-size-predefault;
    line-height: $line-height-presmall;
}

h5 {
    font-size: $font-size-smaller;
    line-height: $line-height-small;
}

h6 {
    font-size: $font-size-smallbefore;
    line-height: $line-height-medium;
}

a {
    color: $color-havelock-blue;
    text-decoration: none;

    &:hover {
        color: $color-sun;
        text-decoration: none;
    }
}

a.orange {
    color: $color-neon-carrot;

    &:hover {
        color: $color-havelock-blue;
    }
}

ul, ol {
    margin-bottom: 1.3em;

    padding-left: $font-size-default * $line-height-small;
}

ul li {
    list-style: disc;
    list-style-position: outside;
}

strong {
    font-family: $font-family-bold;
}
