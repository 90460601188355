$burger: '.burger';

#{$burger} {
    position: absolute;
    display: none;
    right: 10px;
    top: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    outline: 0;

    @include respond-to('increased') {
       display: block;
    }

    &__wrapper {
        position: relative;
        width: 25px;
        height: 17px;
    }

    &__line {
        position: absolute;
        display: block;
        width: 100%;
        height: 3px;
        top: 50%;
        margin-top: -1px;
        background-color: $color-havelock-blue;
        cursor: pointer;
        transition: all 200ms;
    }

    &__line:nth-child(1) {
        transform: translateY(-8px);

        #{$burger}.active & {
            transform: translateY(0) rotate(45deg);
        }
    }

    &__line:nth-child(2) {
        #{$burger}.active & {
            transform: scale(0.1, 1);
        }
    }

    &__line:nth-child(3) {
        transform: translateY(8px);

        #{$burger}.active & {
            transform: translateY(0) rotate(-45deg);
        }
    }
}

.header.active,
.mobileNavigationShow {
    #{$burger} {
        &__line {
            background-color: $color-havelock-blue;
        }
    }
}
