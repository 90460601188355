$companyTable: '.companyTable';

#{$companyTable} {
    position: relative;
    display: block;
    @include contentBlock;
    @include blockMargin;
    padding-bottom: 0;
    padding-top: 0;

    &__wrapper {
        position: relative;
        display: block;
    }

    &__tableData {
        margin: 0px -40px 0;

        @include respond-to('prebig') {
            margin: 0px -20px 0;
        }
    }
}
