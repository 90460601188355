$formCaptcha: '.formCaptcha';

#{$formCaptcha} {
    position: relative;
    display: block;
    vertical-align: top;
    overflow: hidden;

    div {
        @include respond-to('prebig') {
            transform: scale(0.90);
            transform-origin: 0 0;
        }
    }
}
