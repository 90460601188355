$headerPrivate: '.headerPrivate';

#{$headerPrivate} {
    float: right;
    padding: 0 0px;
    line-height: 85px;
    position: relative;

    @include respond-to('increased') {
        display: none;
    }

    &__link {
        font-size: 0;
        display: block;

        svg {
            display: inline-block;
            vertical-align: middle;
        }

        svg path {
            fill: $color-havelock-blue;
            @include transitionDefault;
        }

        &:hover {
            svg path {
                fill: $color-neon-carrot;
            }
        }

        &.active {
            svg path {
                fill: $color-neon-carrot;
            }
        }
    }

    &__box {
        display: none;
        position: absolute;
        padding: 20px;
        background-color: $color-white;
        border-radius: 2px;
        line-height: 1em;
        right: 0;
        top: 70px;
        min-width: 215px;
        z-index: 10;
        @include transitionDefault;
        -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.17);
           -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.17);
                box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.17);

        &:after {
            content: '';
            position: absolute;
            right: 12px;
            top: -8px;
            border: 4px solid transparent;
            border-bottom: 4px solid #fff;
        }
    }

    &__header {
        border-bottom: 1px solid $color-athens-gray-lighter;
        padding-bottom: 15px;
        margin-bottom: 11px;
    }

    &__footer {

    }

    &__login {
        padding: 9px 21px;

        svg {
            display: none;
        }
    }

    &__registration {
        float: right;
        line-height: 32px;
        @include transitionDefault;
        font-size: $font-size-small;
    }

    &__region {
        font-size: 0;
        display: block;

        svg {
            display: inline-block;
            vertical-align: middle;
        }

        svg path {
            fill: $color-neon-carrot;
            @include transitionDefault;
        }

        span {
            display: inline-block;
            vertical-align: middle;
            font-size: $font-size-small;
            color: $color-neon-carrot;
            margin-left: 7px;
            @include transitionDefault;
            border-bottom: 1px dashed $color-neon-carrot;
        }

        &:hover {
            svg path {
                fill: $color-havelock-blue;
            }

            span {
                color: $color-havelock-blue;
                border-bottom: 1px dashed $color-havelock-blue;
            }
        }
    }
}

.mobilePrivate {
    float: none;
    display: block;
    width: 100%;
    position: static;
    padding: 0;
    box-shadow: none;

    #{$headerPrivate} {
        &__header {
            margin: 0;
            padding: 0;
            border: none;
        }

        &__footer {
            display: none;
        }

        &__login {
            display: block;
            float: none;
            background-color: $color-white;
            border-radius: 0;
            border-bottom: 1px solid $color-athens-gray-lighter;
            padding: 0;
            line-height: 50px;
            color: $color-neon-carrot;
            font-size: 0;

            &:hover, &:active {
                background-color: $color-white;
                color: $color-neon-carrot;
            }

            svg {
                display: inline-block;
                vertical-align: middle;
                width: 16px;
            }

            svg path {
                fill: $color-neon-carrot;
            }

            span {
                display: inline-block;
                vertical-align: middle;
                font-size: $font-size-small;
                margin-left: 6px;
            }
        }

        &__registration {
            display: block;
            float: none;
            text-align: center;
            color: $color-neon-carrot;
            line-height: 50px;
            border-bottom: 1px solid $color-athens-gray-lighter;
        }
    }
}
