$comment: '.comment';

#{$comment} {
    position: relative;
    display: block;
    @include contentBlock;
    @include blockMargin;

    &__wrapper {
        position: relative;
        display: block;
    }

    &__header {
        position: relative;
        display: block;
    }

    &__title {
        position: relative;
        display: block;

        h2 {
            @include blockTitle;
        }
    }

    &__content {
        position: relative;
        display: block;
        @include clearfix;
    }

    &__form {
        @include span(7.4);

        @include respond-to('increased') {
            @include span(12);
        }

        .formSubmit__button {
            padding-left: 53px;
            padding-right: 53px;
        }
    }

    &__item {
        margin-bottom: 17px;

        @include f-last(1) {
            margin-bottom: 0;
        }
    }
}
