$loanHistory: '.loanHistory';

#{$loanHistory} {
    position: relative;
    display: block;
    @include contentBlock;
    padding-bottom: 0;
    @include blockMargin;

    &__wrapper {
        position: relative;
        display: block;
    }

    &__breadcrumbs {
        position: relative;
        display: block;
        margin-bottom: 28px;
    }

    &__header {
        position: relative;
        display: block;
        margin-bottom: 24px;
    }

    &__title {
        position: relative;
        display: block;

        h1 {
            margin-bottom: .2em;
        }
    }

    &__subtitle {
        position: relative;
        display: block;

        p {
            @include f-last(1) {
                margin-bottom: 0;
            }
        }
    }

    &__content {
        position: relative;
        display: block;
    }
}
