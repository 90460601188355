$rating: '.rating';

#{$rating} {
    position: relative;
    display: block;
    width: 100%;
    @include sideBarBlock;
    @include blockMargin;

    &__header {

    }

    &__content {

    }

    &__list {
        @include list-reset;
    }

    &__item {
        @include list-reset;
        margin-top: 14px;

        a {
            @include clearfix;
        }
    }

    &__left {
        @include span(3.6);
    }

    &__right {
        @include span(8.4 last);
    }

    &__logo {
        img {
            max-width: 60px;
            margin-top: 5px;
        }
    }

    &__name {
        p {
            color: $color-black;
            margin: 0;
            font-size: $font-size-predefault;
        }
    }

    &__star {

    }

    &__reviews {
        p {
            color: $color-mine-shaft;
            margin: 0;
            font-size: $font-size-smaller;
            opacity: 1;

            span {
                opacity: 0.5;
            }
        }
    }
}
