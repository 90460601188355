$tableData: '.tableData';

#{$tableData} {
    margin: 0;

    &__wrapper {

    }

    &__header {

    }

    &__content {

    }

    &__footer {

    }

    &__item {
        box-sizing: border-box;
        background-color: $color-white;
        border-top: 1px solid $color-porcelain;
        font-size: 0;
        padding: 30px 0;
        position: relative;
        background-image: url(../images/bg-triangle.svg);
        background-repeat: no-repeat;
        background-position: -30px 50%;
        @include transitionDefault;

        @include respond-to('intermediary') {
            background-position: -50px 50%;
        }

        @include respond-to('small') {
            padding: 20px 0 0;
            background-image: none;
        }

        &:hover {
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.17);
            z-index: 102;
            border-color: transparent;

            & + .tableData__item {
                border-color: transparent;
            }

            & + .active {
                border-color: $color-casablanca;
            }
        }

        &.active {
            background-color: $color-bleach-white;
            border: 2px solid $color-casablanca;
            position: relative;

            @include respond-to('small') {
                background-color: $color-white;
            }

            #{$tableData} {
                &__label {
                    display: block;
                }

                &__center {
                    @include respond-to('small') {
                        background-color: $color-bleach-white;
                    }
                }

                &__right {
                    @include respond-to('small') {
                        background-color: $color-bleach-white;
                    }
                }
            }
        }
    }

    &__left {
        display: inline-block;
        width: 15.5%;
        font-size: $font-size-default;
        vertical-align: middle;
        text-align: center;

        @include respond-to('preincreased') {
            width: 20%;
        }

        @include respond-to('small') {
            width: 40%;
            vertical-align: top;
            max-width: 120px;
        }
    }

    &__center {
        display: inline-block;
        width: 62%;
        font-size: $font-size-default;
        vertical-align: middle;
        padding-left: 15px;

        @include respond-to('huge') {
            padding-left: 25px;
        }

        @include respond-to('preincreased') {
            width: 57.5%;
        }

        @include respond-to('small') {
            width: 100%;
            vertical-align: top;
            display: block;
            padding-left: 20px;
            padding-top: 0px;
            margin-top: -10px;
        }
    }

    &__right {
        display: inline-block;
        width: 22.5%;
        font-size: $font-size-default;
        vertical-align: middle;
        text-align: right;
        padding-right: 20px;

        @include respond-to('small') {
            width: 100%;
            vertical-align: top;
            display: block;
            padding-left: 20px;
            padding-bottom: 25px;
            padding-top: 25px;
            @include clearfix;
        }
    }

    &__label {
        position: absolute;
        left: -9px;
        top: -9px;
        background-color: $color-sun;
        display: block;
        font-size: $font-size-predefault;
        font-family: $font-family-bold;
        color: $color-white;
        line-height: $line-height-prelittle;
        padding: 7px 14px 6px;
        display: none;
        z-index: 103;


        &:before {
            position: absolute;
            content: '';
            bottom: -5px;
            left: 0;
            display: block;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAFCAYAAACJmvbYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Q0UyMEJDMDA5MkExMTFFNkFDNjlFQ0RGMzVBOEQ5NzQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Q0UyMEJDMDE5MkExMTFFNkFDNjlFQ0RGMzVBOEQ5NzQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDRTIwQkJGRTkyQTExMUU2QUM2OUVDREYzNUE4RDk3NCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDRTIwQkJGRjkyQTExMUU2QUM2OUVDREYzNUE4RDk3NCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PpeNfewAAABASURBVHjaYnw4iyGagYFhGhDzMaABJiBeCsQGQHwMmyQI3AdieyBuBOI/6JIMUMEGqKIH6JIwADJeH4iXAQQYAD2eCvB4/59mAAAAAElFTkSuQmCC');
            background-repeat: no-repeat;
            width: 7px;
            height: 5px;
        }

        &:after {
            position: absolute;
            content: '';
            top: 0px;
            right: -12px;
            display: block;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAcCAYAAABVo158AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Q0UyMEJDMDQ5MkExMTFFNkFDNjlFQ0RGMzVBOEQ5NzQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Q0UyMEJDMDU5MkExMTFFNkFDNjlFQ0RGMzVBOEQ5NzQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDRTIwQkMwMjkyQTExMUU2QUM2OUVDREYzNUE4RDk3NCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDRTIwQkMwMzkyQTExMUU2QUM2OUVDREYzNUE4RDk3NCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjrXFm8AAADSSURBVHjaYvyzWfQ/A/FgGRMJih8AcRaxGv4CcQwQfyRWQysQHwUxiNFwHIibYBxCGj4BcTTUSURpyAbi+8gC+DQsA+Il6IJM+IIQmwQTviAkVgM8CInRgBKEhDRgBCEhDRhBiE8D1iDEp+EgsUkWpmEyENuSooENiNcCsQqxGkBAFIi3AzE/sRoYoDasg9rIQGxMOwHxdFI0gEASEFeQogEE2oA4kBQNjNDINCdWAwhwAfEGIJYhVgMISADxNlhwE1vM6EKdx0xKyecDSkIAAQYAdJ4kkt2tIYMAAAAASUVORK5CYII=');
            background-repeat: no-repeat;
            width: 12px;
            height: 28px;
        }
    }

    &__logo {
        max-width: 100px;
        display: inline-block;

        @include respond-to('intermediary') {
            max-width: 90px;
        }

        img {
            margin: 0;
            max-height: 60px;
        }
    }

    &__rating {
        display: inline-block;
    }

    &__top {
        display: block;
        width: 100%;
        font-size: 0;

        @include respond-to('small') {
            position: absolute;
            top: 30px;
            left: 40%;
            width: 60%;
        }
    }

    &__bottom {
        display: block;
        width: 100%;

        @include respond-to('small') {
            position: relative;
            top: 10px;
        }
    }

    &__name {
        display: inline-block;
        vertical-align: middle;

        a {
            font-size: $font-size-premedium;
            font-family: $font-family-semibold;
            color: $color-havelock-blue;
            line-height: $line-height-prelittle;
            @include transitionDefault;

            &:hover {
                color: $color-sun;
            }
        }
    }

    &__payment {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;

        svg {
            margin-left: 10px;
        }

        svg path {
            fill: $color-silver;
        }

        @include respond-to('small') {
            display: block;
            margin: 10px 0 0 0;

            svg {
                margin-left: 0px;
                margin-right: 10px;
            }
        }
    }

    &__info {
        font-size: 0;
        margin-top: 20px;
    }

    &__infoItem {
        display: inline-block;
        margin-right: 38px;

        @include f-last(1) {
            margin-right: 0px;
        }

        @include respond-to('huge') {
            margin-right: 15px;
        }

        @include respond-to('preincreased') {
            @include f-last(2) {
                display: none;
            }
        }
    }

    &__infoItemName {
        font-size: $font-size-smaller;
        font-family: $font-family-regular;
        line-height: $line-height-prelittle;
        color: $color-mine-shaft;
        opacity: 0.5;
        margin-top: 5px;
    }

    &__infoItemText {
        font-size: $font-size-predefault;
        font-family: $font-family-regular;
        line-height: $line-height-prelittle;
        color: $color-mine-shaft;
    }

    &__comparison {
        @include respond-to('small') {
            display: inline-block;
            float: right;
            vertical-align: middle;
            font-size: 0;
            margin-top: 9px;
        }
    }

    &__linkComparison {
        font-size: $font-size-smaller;
        font-family: $font-family-regular;
        line-height: $line-height-prelittle;
        color: $color-havelock-blue;
        cursor: pointer;
        @include transitionDefault;

        svg {
            display: none;

            @include respond-to('small') {
                display: block;
            }
        }

        svg path {
            fill: $color-havelock-blue;
        }

        span {
            @include respond-to('small') {
                display: none;
            }
        }

        &:hover {
            color: $color-neon-carrot;
        }
    }

    &__addedComparison {
        font-size: $font-size-smaller;
        font-family: $font-family-regular;
        line-height: $line-height-prelittle;
        color: $color-neon-carrot;

        svg {
            height: 8px;
            display: inline-block;

            @include respond-to('small') {
                height: 10px;
                display: inline-block;
                vertical-align: middle;
            }
        }

        svg path {
            fill: $color-neon-carrot;
        }

        span {
            display: inline-block;
            vertical-align: middle;
            display: none;

            @include respond-to('small') {
                display: inline-block;
            }

            svg {
                display: none;

                @include respond-to('small') {
                    display: block;
                    height: 18px;
                }
            }

            svg path {
                fill: $color-neon-carrot;
            }

            @include respond-to('small') {
                text-indent: -9999px;
            }

            &.spanText {
                width: auto;
                display: inline-block;

                @include respond-to('small') {
                    display: none;
                }
            }
        }
    }

    &__more {
        margin-top: 13px;
        display: inline-block;

        @include respond-to('small') {
            float: left;
            margin-top: 0;
            vertical-align: middle;
        }

        a {
            @include respond-to('small') {
               padding-left: 60px;
               padding-right: 60px;
            }
        }
    }

    &__sort {
        background-color: $color-porcelain;
        font-size: 0;
        padding: 8px 18px 7px;
        border-top: 1px solid $color-porcelain;
        @include clearfix;

        .sort {
            display: inline-block;
            vertical-align: middle;
            float: right;

            @include respond-to('increased') {
                display: block;
                float: none;
            }

            @include respond-to('small') {
                float: none;
                display: block;

                .sort__title {
                    display: block;
                    margin-top: 5px;
                }
                .sort__list {
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }
        }

        .sort__title__desktop {
            @include respond-to('increased') {
                display: none;
            }
        }

        .sort__title__mobile {
            display: none;

            @include respond-to('increased') {
                display: inline;
            }
        }
    }

    &__sortName {
        display: inline-block;
        font-size: $font-size-smaller;
        vertical-align: middle;

        @include respond-to('increased') {
            display: none;
        }
    }

    &__footer {
        text-align: center;
        border-top: 1px solid $color-porcelain;
        padding-top: 10px;
        padding-bottom: 15px;
    }

    &__disclosure {
        a {
            font-size: $font-size-predefault;
            font-family: $font-family-regular;
            color: $color-neon-carrot;
            line-height: $line-height-prelittle;
            border-bottom: 1px dashed $color-neon-carrot;
            @include transitionDefault;

            &:hover {
                color: $color-havelock-blue;
                border-bottom: 1px dashed $color-havelock-blue;
            }
        }
    }
}
