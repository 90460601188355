$navigationDesktop: #{'.navigationDesktop'};

#{$navigationDesktop} {
    margin-top: 0px;
    background-color: $color-havelock-blue;

    @include respond-to('increased') {
        display: none;
    }

    &__wrapper {
        position: relative;
        @include container;


        @include respond-to('huge') {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    &__list,
    &__item {
        @include list-reset;
    }

    &__list {
        display: table;
        width: 97%;
        height: 55px;
        float: left;

        ul,
        ul li {
            @include list-reset;
        }
    }

    &__search {
        width: 3%;
        float: right;
        margin: 0;
        padding: 0;
    }

    &__item {
        position: relative;
        display: table-cell;
        vertical-align: middle;
        height: 55px;
        text-align: center;
        text-decoration: none;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            width: 2px;
            border-left: 1px solid #4682cd;
            height: 16px;
            top: 50%;
            left: 0px;
            margin-top: -8px;
            background-color: #5490db;
            @include transitionDefault;
        }

        @include f-last(1) {
            &:after {
                content: '';
                position: absolute;
                width: 2px;
                border-left: 1px solid #4682cd;
                height: 16px;
                top: 50%;
                right: 0px;
                margin-top: -8px;
                background-color: #5490db;
                @include transitionDefault;
            }
        }

        ul {
            position: absolute;
            display: none;
            min-width: 330px;
            top: 100%;
            left: 0;
            padding-top: 0;
            padding-bottom: 0;
            text-align: left;
            background-color: $color-white;
            box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.3);
            border-radius: 0 4px 4px 4px;
            z-index: 2;

            li {
                a {
                    display: block;
                    padding-top: 12px;
                    padding-bottom: 13px;
                    padding-left: 35px;
                    padding-right: 20px;
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 15px;
                    color: $color-mine-shaft;
                    text-decoration: none;
                    transition: all .2s ease;

                    &:hover {
                        text-decoration: none;
                        // background-color: $color-mariner-blue;
                        color: $color-white;
                    }
                }
            }

            li.active {
                color: #558c00;
                text-decoration: none;
            }
        }

        &:hover {

            &:before {
                opacity: 0;
            }

            #{$navigationDesktop} {
                &__link {
                    span {
                        height: 55px;
                        line-height: 55px;
                        color: $color-white;
                        background-color: $color-mariner-blue !important;
                        border-radius: 0px;
                    }
                }
            }
        }

        &:hover + &{

            &:before {
                opacity: 0;
            }
        }
    }

    &__item.active {
        // Hide divider in active menu item
        //&:before {
        //    display: none;
        //}

        #{$navigationDesktop} {
            &__link {
                span {
                    color: $color-white;
                    background-color: $color-mariner-blue;
                    height: 100%;
                    line-height: 55px;
                }
            }
        }
    }

    &__item.dropdown {
        overflow: visible;

        #{$navigationDesktop} {
            &__link {
                span {
                    border-radius: 0px 0px 0 0 !important;
                    position: relative;

                    // &:after {
                    //     position: absolute;
                    //     content: '';
                    //     left: 0;
                    //     bottom: -2px;
                    //     width: 100%;
                    //     height: 2px;
                    //     display: block;
                    //     background-color: $color-mariner-blue;
                    //     z-index: 7;
                    // }
                }
            }
        }
    }

    &__item.active.dropdown {
        #{$navigationDesktop} {
            &__link {
                span {
                    border-radius: 0px 0px 0 0 !important;
                    position: relative;

                    &:after {
                        position: absolute;
                        content: '';
                        left: 0;
                        bottom: -2px;
                        width: 100%;
                        height: 2px;
                        display: block;
                        background-color: $color-mariner-blue;
                        z-index: 7;
                    }
                }
            }
        }

    }

    &__link {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 55px;
        font-family: $font-family-bold;
        font-size: $font-size-small;
        color: $color-white;
        line-height: 55px;
        text-transform: uppercase;
        z-index: 5;
        transition: none;

        span {
            position: relative;
            display: inline-block;
            width: 100%;
            height: 28px;
            margin: auto;
            padding-left: 30px;
            padding-right: 30px;
            line-height: 28px;
            border-radius: 0px;
            @include transitionDefault;

            @include respond-to('huge') {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }

    &__active {
        // background-color: $color-atlantis;
        color: $color-white !important;
    }
}

.navigationMobile__search {
    float: none;
    width: 100%;
    background-color: $color-havelock-blue;
    border-top: 1px solid #5490db;
    position: relative;
    overflow: hidden;

    .search__link {
        text-align: center;
    }

    .search__link svg {
        display: none;
    }

    .search__link span {
        display: block;
        padding-top: 18px;
        padding-bottom: 16px;
    }

    .search__btn {
        line-height: 48px;
    }

    .search__close {
        line-height: 48px;
        right: 10px;
    }
}
