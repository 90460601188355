$tooltipster-base: '.tooltipster-base';

#{$tooltipster-base} {
    .tooltipster-box {
        border: none;
        background: $color-white;
        border-radius: 3px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    }

    .tooltipster-content {
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 10px;
        padding-right: 10px;
        font-family: $font-family-regular;
        font-size: $font-size-smaller;
        line-height: $line-height-prelittle;
        color: $color-cod-gray;
        text-align: center;
        text-transform: uppercase;
    }

    .tooltipster-arrow-border {
        border-top-color: $color-white !important;
    }

    .tooltipster-arrow-background {
        border-top-color: $color-white !important;
    }
}
