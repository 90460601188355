$companyInfo: '.companyInfo';

#{$companyInfo} {
    position: relative;
    display: block;
    @include contentBlock;
    @include blockMargin;

    &__wrapper {
        position: relative;
        display: block;
        @include clearfix;
    }

    &__breadcrumbs {
        position: relative;
        display: block;
        margin-bottom: 10px;
    }

    &__col {
        position: relative;
        display: block;
    }

    &__col.col_1 {
        @include span(4.5 last);

        @include respond-to('intermediary') {
            @include span(12);
        }
    }

    &__col.col_2 {
        @include span(7.5);

        @include respond-to('intermediary') {
            @include span(12);
        }
    }

    &__header {
        position: relative;
        display: block;
        margin-bottom: 12px;
        padding-top: 20px;
    }

    &__title {
        position: relative;
        display: block;

        h1 {
            @include blockTitle;
            margin-bottom: .3em;
        }
    }

    &__stars {
        position: relative;
        display: block;

        .stars {
            .stars__list {
                margin-top: 0;
            }

            .stars__left {
                width: auto;
            }

            .stars__item {
                margin-right: 5px;
            }

            svg {
                width: 18px !important;
                height: 18px !important;
            }
        }
    }

    &__content {
        position: relative;
        display: block;
    }

    &__list {
        @include clearfix;
    }

    &__list,
    &__item {
        @include list-reset;
    }

    &__item {
        position: relative;
        display: block;
        margin-bottom: 6px;
        font-size: $font-size-predefault;
        line-height: $line-height-little;

        @include f-last(1) {
            margin-bottom: 0;
        }

        img {
            display: inline;
            vertical-align: middle;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0;
        }
    }

    &__label {
        font-size: $font-size-smaller;
        color: $color-boulder;
    }

    &__preview {
        position: relative;
        display: block;

        img {
            margin-right: 0;
            margin-bottom: 0;

            @include respond-to('intermediary') {
                margin-left: 0;
            }
        }
    }
}
