$graphics: '.graphics';

#{$graphics} {
    position: relative;
    display: block;

    &__wrapper {
        position: relative;
        display: block;
    }

    &__list {
        @include clearfix;
    }

    &__list,
    &__item {
        @include list-reset;
    }

    &__item {
        position: relative;
        display: block;
        float: left;
        width: 14%;
        margin-left: 3%;
        margin-right: 3%;
        opacity: .4;
        @include transitionDefault;

        img {
            margin-bottom: 0;
        }
    }

    &.graphics {
        &__1 {
            #{$graphics} {
                &__item {
                    @include f-first(1) {
                        opacity: 1;
                    }
                }
            }
        }

        &__2 {
            #{$graphics} {
                &__item {
                    @include f-first(2) {
                        opacity: 1;
                    }
                }
            }
        }

        &__3 {
            #{$graphics} {
                &__item {
                    @include f-first(3) {
                        opacity: 1;
                    }
                }
            }
        }

        &__4 {
            #{$graphics} {
                &__item {
                    @include f-first(4) {
                        opacity: 1;
                    }
                }
            }
        }

        &__5 {
            #{$graphics} {
                &__item {
                    @include f-first(5) {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
