$offer: '.offer';

#{$offer} {
    position: relative;
    display: block;
    @include contentBlock;
    @include blockMargin;
    padding-bottom: 0;

    &__wrapper {
        position: relative;
        display: block;
    }

    &__breadcrumbs {
        position: relative;
        display: block;
        margin-bottom: 13px;
    }

    &__header {
        position: relative;
        display: block;
    }

    &__title {
        position: relative;
        display: block;

        h1 {
            margin-bottom: .3em;
        }
    }

    &__content {
        position: relative;
        display: block;
    }

    &__text {
        position: relative;
        display: block;
        margin-bottom: 0px;
        font-size: $font-size-predefault;
        line-height: $line-height-presmall;

        p {
            @include f-last(1) {
                margin-bottom: 0;
            }
        }
    }

    &__tableData {
        margin: 25px -40px 0;

        @include respond-to('prebig') {
            margin: 25px -20px 0;
        }
    }
}
