$formStars: '.formStars';

#{$formStars} {
    position: relative;
    display: block;
    width: 100%;

    &__wrapper {
        position: relative;
        display: block;
        width: 100%;
    }

    &__label {
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: .3em;
        font-family: $font-family-bold;
        font-size: $font-size-predefault;
        line-height: $line-height-little;
        color: $color-cod-gray;
    }

    .stars {
        height: 40px;
    }

    .stars__left {
        width: auto;
    }

    .stars__item {
        margin-right: 6px;
        cursor: pointer;

        svg {
            width: 23px !important;
            height: 21px !important;
        }
    }
}
