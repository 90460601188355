$loan: '.loan';

#{$loan} {
    position: relative;
    display: block;
    @include contentBlock;
    @include blockMargin;

    &__wrapper {
        position: relative;
        display: block;
    }

    &__breadcrumbs {
        position: relative;
        display: block;
        margin-bottom: 28px;
    }

    &__header {
        position: relative;
        display: block;
    }

    &__title {
        position: relative;
        display: block;

        h1 {
            margin-bottom: .4em;
        }
    }

    &__content {
        position: relative;
        display: block;
    }

    &__text {
        position: relative;
        display: block;
        margin-bottom: 26px;
        font-size: $font-size-predefault;
        line-height: $line-height-large;

        p {
            @include f-last(1) {
                margin-bottom: 0;
            }
        }
    }

    &__search {
        position: relative;
        display: block;
        @include clearfix;
    }

    &__form {
        @include span(6.4);

        @include respond-to('increased') {
            @include span(12);
        }

        .formSubmit__button {
            padding-left: 53px;
            padding-right: 53px;
        }
    }

    &__item {
        margin-bottom: 17px;

        @include f-last(1) {
            margin-bottom: 0;
        }
    }
}
