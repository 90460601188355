$pagination: '.pagination';

#{$pagination} {
    text-align: center;
    @include clearfix;

    &__prev {
        @include span(3);
        text-align: left;
        margin-top: 5px;

        @include respond-to('small') {
            @include span(6);
        }

        .btn {
            padding: 3px 15px 4px 13px;
            min-width: 77px;

            span {
                font-size: $font-size-smaller;
                font-family: $font-family-regular;
            }

            svg {
                height: 9px;
                margin-right: 2px;
            }

            svg polygon {
                fill: $color-white;
            }
        }
    }

    &__next {
        @include span(3 last);
        text-align: right;
        margin-top: 5px;

        @include respond-to('small') {
            @include span(6 last);
        }

        .btn {
            font-size: $font-size-smaller;
            font-family: $font-family-regular;
            padding: 3px 9px 4px 12px;
            min-width: 77px;

             span {
                font-size: $font-size-smaller;
                font-family: $font-family-regular;
            }

            svg {
                height: 9px;
                margin-left: 2px;
            }

            svg polygon {
                fill: $color-white;
            }
        }
    }

    &__list {
        padding: 0;
        margin: 0;
        @include span(6);
        font-size: 0;

        @include respond-to('small') {
            @include span(12 last);
            margin-top: 10px;
        }
    }

    &__item {
        display: inline-block;
        width: 28px;
        height: 28px;
        line-height: 28px;

        a {
            font-size: $font-size-predefault;
            font-family: $font-family-semibold;
            color: $color-mine-shaft;

            &:hover {
                color: $color-havelock-blue;
            }
        }

        span {
            font-size: $font-size-predefault;
            color: $color-mine-shaft;
            font-family: $font-family-semibold;
        }
    }

    &__item.active {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-color: $color-sun;

        a {
            color: $color-white;
        }
    }
}
