$sideReviews: '.sideReviews';

#{$sideReviews} {
    position: relative;
    display: block;
    width: 100%;
    @include sideBarBlock;
    @include blockMargin;

    &__title {
        padding-right: 40px;

        h2 {
            letter-spacing: 00;
        }
    }

    &__content {
        position: relative;
        display: block;

        .reviews {
            padding-left: 0;
            padding-right: 0;
            padding-top: 8px;
            padding-bottom: 7px;
            box-shadow: none;
        }

        .reviews__child {
            margin-bottom: 13px;

            @include f-last(1) {
                margin-bottom: 0;
            }
        }

        .reviews__text {
            margin-bottom: 0;
        }

        .reviews__profit {
            display: none;
        }
    }

    &__btn {
        a {
            font-size: $font-size-predefault;
            color: $color-neon-carrot;

            &:hover {
                color: $color-havelock-blue;
            }
        }
    }
}
