$compareData: '.compareData';

#{$compareData} {
    position: relative;
    display: block;
    @include contentBlock;
    padding-bottom: 0;
    @include blockMargin;

    @include respond-to('prebig') {
        cursor: pointer;
    }

    &__wrapper {
        position: relative;
        display: block;
    }

    &__breadcrumbs {
        position: relative;
        display: block;
        margin-bottom: 28px;
    }

    &__header {
        position: relative;
        display: block;
        margin-bottom: 5px;

        @include respond-to('increased') {
            margin-bottom: 25px;
        }
    }

    &__title {
        position: relative;
        display: block;
        @include clearfix;

        h1 {
            float: left;
            margin-right: 30px;
            margin-bottom: .4em;

            @include respond-to('increased') {
                float: none;
            }
        }
    }

    &__btn {
        position: relative;
        margin-top: 4px;
    }

    &__arrows {
        position: relative;
        margin-top: 11px;
        float: right;
        font-size: 0;

        svg {
            polygon {
                fill: $color-havelock-blue;
                @include transitionDefault;
            }
        }
    }

    &__next,
    &__prev {
        display: inline-block;
        vertical-align: top;
        width: 12px;
        height: 21px;
        cursor: pointer;

        &:hover {
            svg {
                polygon {
                    fill: $color-sun;
                }
            }
        }
    }

    &__prev {
        margin-right: 35px;
    }

    &__content {
        position: relative;
        display: block;
        margin-left: -40px;
        margin-right: -40px;
        background-color: $color-black-haze;
        border-top: 1px solid $color-athens-gray-lighter;

        @include respond-to('prebig') {
            margin-left: -20px;
            margin-right: -20px;
        }
    }

    &__rotator {
        position: relative;
        display: block;
        @include clearfix;
    }

    &__heading {
        position: relative;
        display: block;
        float: left;
        width: 163px;

        @include respond-to('prebig') {
            width: 130px;
        }
    }

    &__heading__list {
        @include clearfix;
    }

    &__heading__list,
    &__heading__item {
        @include list-reset;
    }

    &__heading__item {
        position: relative;
        display: block;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 30px;
        font-family: $font-family-bold;
        font-size: $font-size-predefault;
        line-height: $line-height-little;
        border-top: 1px solid $color-athens-gray-lighter;

        @include respond-to('prebig') {
            padding-left: 15px;
            padding-top: 15px;
            padding-bottom: 15px;
            font-size: $font-size-small;
        }

        @include f-first(1) {
            border: 0;
        }
    }

    &__data {
        position: relative;
        display: block;
        margin-left: 163px;
        background-color: $color-white;

        @include respond-to('prebig') {
            margin-left: 130px;
        }
    }

    &__data__list {
        @include clearfix;
    }

    &__data__list,
    &__data__item {
        @include list-reset;
    }

    &__data__item {
        position: relative;
        display: block;
    }

    &__data__list__content {
        @include clearfix;
    }

    &__data__list__content,
    &__data__list__content__item {
        @include list-reset;
    }

    &__data__list__content__item {
        position: relative;
        display: block;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
        border-top: 1px solid $color-athens-gray-lighter;

        @include respond-to('increased') {
            text-align: center;
        }

        @include respond-to('prebig') {
            padding-top: 15px;
            padding-bottom: 15px;
        }

        @include f-first(1) {
            border: 0;
        }
    }

    &__preview {
        position: relative;
        display: block;
        margin-bottom: 15px;
        height: 45px;
        line-height: 45px;
        text-align: center;

        img {
            display: inline-block;
            vertical-align: middle;
            max-height: 45px;
            margin-bottom: 0;
        }
    }

    &__name {
        position: relative;
        display: block;
        margin-bottom: .4em;
        font-family: $font-family-bold;
        font-size: $font-size-predefault;
        line-height: $line-height-little;
        text-align: center;
    }

    &__stars {
        position: relative;
        display: block;
        text-align: center;

        .stars__left {
            display: inline-block;
            float: none;
        }
    }

    &__info {
        position: relative;
        display: block;
        padding-left: 12px;
        padding-right: 12px;
        font-size: $font-size-predefault;
        line-height: $line-height-little;
    }

    &__data__remove {
        position: absolute;
        display: block;
        width: 24px;
        height: 24px;
        top: 0;
        right: 0;
        cursor: pointer;
        z-index: 5;

        svg {
            position: absolute;
            display: block;
            width: 10px;
            height: 10px;
            top: 50%;
            top: 20px;
            right: 10px;
            margin-top: -5px;
            margin-left: auto;
            margin-right: auto;

            polygon {
                fill: $color-mischka;
            }
        }
    }

    .lSPager {
        display: none;
    }
}

#{$compareData}.first {
    #{$compareData} {
        &__prev {
            pointer-events: none;

            svg {
                polygon {
                    fill: $color-athens-gray-lighter;
                }
            }
        }
    }
}

#{$compareData}.last {
    #{$compareData} {
        &__next {
            pointer-events: none;

            svg {
                polygon {
                    fill: $color-athens-gray-lighter;
                }
            }
        }
    }
}

.js_touch {
    #{$compareData} {
        &__arrows {
            &:hover {
                svg {
                    polygon {
                        fill: $color-havelock-blue;
                    }
                }
            }
        }
    }

    #{$compareData}.first {
        #{$compareData} {
            &__prev {
                pointer-events: none;

                svg {
                    polygon {
                        fill: $color-athens-gray-lighter;
                    }
                }
            }
        }
    }

    #{$compareData}.last {
        #{$compareData} {
            &__next {
                pointer-events: none;

                svg {
                    polygon {
                        fill: $color-athens-gray-lighter;
                    }
                }
            }
        }
    }
}
