$breadcrumbs: '.breadcrumbs';

#{$breadcrumbs} {
    position: relative;
    display: block;

    &__list {
        @include clearfix;
    }

    &__list,
    &__item {
        @include list-reset;
    }

    &__item {
        position: relative;
        display: inline;

        a {
            position: relative;
            display: inline;
            margin-right: 10px;
            font-size: $font-size-smaller;
            line-height: $line-height-small;

            &:after {
                content: '';
                position: relative;
                display: inline-block;
                vertical-align: middle;
                width: 6px;
                height: 10px;
                margin-left: 14px;
                background-repeat: no-repeat;
                background-position: 0 0;
                background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iOXB4IiB2aWV3Qm94PSIwIDAgNiA5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA2IDkiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiNiZWJlYmUiIGQ9Ik0wLjk0MSwwLjA5NGMtMC4xNDItMC4xMzMtMC4zNjQtMC4xMjMtMC40OTQsMC4wMmMtMC4xMzEsMC4xNDItMC4xMjMsMC4zNjMsMC4wMiwwLjQ5NGw0LjEzOCwzLjgyNEwwLjQ3MSw4LjEyOWMtMC4xNDQsMC4xMjktMC4xNTYsMC4zNS0wLjAyNywwLjQ5NGMwLjA2OSwwLjA3NiwwLjE2NSwwLjExNSwwLjI2MSwwLjExNWMwLjA4MywwLDAuMTY3LTAuMDI5LDAuMjMzLTAuMDg4bDQuNzA4LTQuMjA5TDAuOTQxLDAuMDk0eiIvPjwvc3ZnPg==);
            }
        }

        span {
            position: relative;
            display: inline;
            font-size: $font-size-smaller;
            line-height: $line-height-small;
            color: $color-silver;
        }
    }
}
