$share: '.share';

#{$share} {
    position: relative;
    display: block;

    &__wrapper {
        position: relative;
        display: block;
    }

    &__content {
        position: relative;
        display: block;
    }
}
