$advice: '.advice';

#{$advice} {
    position: relative;
    display: block;
    width: 100%;
    @include sideBarBlock;
    @include blockMargin;
    background-image: url(../images/logo-babka.png), url(../images/bg-advice.png);
    background-position: 96% 5px, bottom right;
    background-repeat: no-repeat, no-repeat;

    @include respond-to('prebig') {
        padding-right: 60px;
    }

    &__title {
        padding-right: 40px;
        h2 {
            letter-spacing: 0px;
        }
    }

    &__text {
        margin-top: 5px;

        p {
            margin: 0;
            font-size: $font-size-predefault;
            line-height: $line-height-presmall;
            color: $color-black;
            letter-spacing: 0px;
        }
    }

    &__btn {
        margin-top: 10px;

        a {
            font-size: $font-size-predefault;
            color: $color-neon-carrot;

            &:hover {
                color: $color-havelock-blue;
            }
        }
    }
}
