$formRadio: '.formRadio';

#{$formRadio} {
    position: relative;
    display: block;
    width: 100%;

    &__title {
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: .3em;
        font-family: $font-family-bold;
        font-size: $font-size-predefault;
        line-height: $line-height-little;
        color: $color-cod-gray;

        span {
            font-family: $font-family-regular;
            font-size: $font-size-smaller;
            color: $color-silver;
        }
    }

    &__list {
        @include clearfix;
    }

    &__list,
    &__item {
        @include list-reset;
    }

    &__item {
        position: relative;
        display: block;
        margin-bottom: 21px;

        @include f-last(1) {
            margin-bottom: 0;
        }
    }

    &__item.disabled {
        #{$formRadio} {
            &__label {
                cursor: default;
            }

            &__icon {
                border-color: $color-mystic !important;
                cursor: default;
                opacity: .6;

                &:after {
                    //background-color: $color-casper !important;
                }
            }

            &__text {
                color: $color-black !important;
                opacity: .6;
            }
        }
    }

    &__input {
        position: absolute;
        left: -9999px;
    }

    &__label {
        position: relative;
        display: block;
        width: 100%;
        min-height: 20px;
        padding-left: 24px;
        padding-top: 0;
        padding-right: 15px;
        cursor: pointer;
        @include transitionDefault;

        &:hover {
            #{$formRadio} {
                &__icon {
                    border-color: $color-sun;
                }
            }
        }
    }

    &__icon {
        position: absolute;
        display: block;
        width: 16px;
        height: 16px;
        top: 0;
        left: 0;
        border: 1px solid $color-mystic;
        border-radius: 50%;
        cursor: pointer;
        @include transitionDefault;

        &:after {
            content: '';
            position: absolute;
            display: block;
            width: 6px;
            height: 6px;
            top: 50%;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            margin-top: -3px;
            background-color: $color-white;
            border-radius: 50%;
            opacity: 0;
            @include transitionDefault;
        }
    }

    &__input:checked + &__label {
        #{$formRadio} {
            &__icon {
                border-color: $color-sun;
                background-color: $color-sun;

                &:after {
                    opacity: 1;
                }
            }
        }
    }

    &__text {
        font-family: $font-family-regular;
        font-size: $font-size-predefault;
        line-height: $line-height-little;
        color: $color-black;
        text-transform: none;

        img {
            margin-left: 0;
            margin-bottom: 0;
        }
    }
}
