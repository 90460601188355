$calculator: '.calculator';

#{$calculator} {
    position: relative;
    display: block;
    @include contentBlock;
    @include blockMargin;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;

    @include respond-to('prebig') {
        padding-left: 0;
        padding-right: 0;
    }

    &__wrapper {
        position: relative;
        display: block;
    }

    &__header {
        position: relative;
        display: none;

        @include respond-to('medium') {
            display: block;
        }
    }

    &__title {
        position: relative;
        display: block;

        h2 {
            @include blockTitle;
        }
    }

    &__form {
        position: relative;
        display: block;
        @include clearfix;
    }

    &__col {
        position: relative;
        display: block;
    }

    &__col.col_1 {
        @include span(8);
        padding-top: 46px;
        padding-left: 38px;
        padding-bottom: 32px;
        z-index: 2;

        @include respond-to('increased') {
            @include span(12);
            height: auto !important;
            padding-left: 40px;
            padding-right: 40px;
        }

        @include respond-to('prebig') {
            padding-left: 20px;
            padding-right: 20px;
        }

        @include respond-to('medium') {
            padding-top: 20px;
        }

        @include respond-to('small') {
            padding-bottom: 66px;
        }
    }

    &__col.col_2 {
        @include span(4 last);
        padding-top: 46px;
        padding-bottom: 40px;
        padding-left: 13px;
        background-color: $color-havelock-blue;

        @include respond-to('increased') {
            @include span(12);
            height: auto !important;
            padding-left: 40px;
            padding-right: 40px;
        }

        @include respond-to('prebig') {
            padding-left: 20px;
            padding-right: 20px;
        }

        @include respond-to('medium') {
            padding-top: 18px;
            padding-bottom: 32px;
        }

        &:before {
            content: '';
            position: absolute;
            width: 200px;
            top: -2px;
            bottom: -2px;
            right: 100%;
            margin-right: -5px;
            background-repeat: no-repeat;
            background-position: 100% 50%;
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIxMjcuNXB4IiBoZWlnaHQ9IjM1OHB4IiB2aWV3Qm94PSIwIDAgMTI3LjUgMzU4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjcuNSAzNTgiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBmaWxsPSIjNEI4N0QyIiBkPSJNMCwwbDExNi45MjMsMTc2LjM3OWMxLjQ2MiwxLjQ2MywxLjQ2MiwzLjgzNCwwLDUuMjk3TDAsMzU4aDEyNy41VjBIMHoiLz48L3N2Zz4=);
            background-size: auto 100%;

            @include respond-to('increased') {
                display: none;
            }

            @include respond-to('small') {
                display: block;
                width: auto;
                height: 66px;
                top: auto;
                right: auto;
                left: -2px;
                right: -2px;
                bottom: 100%;
                margin-right: 0;
                margin-bottom: -2px;
                background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIzMDBweCIgaGVpZ2h0PSI2NnB4IiB2aWV3Qm94PSIwIDAgMzAwIDY2IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAzMDAgNjYiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBmaWxsPSIjNEI4N0QyIiBkPSJNMzAwLDBMMTUyLjE5Niw2MC41MjVjLTEuMjI2LDAuNzU3LTMuMjEzLDAuNzU3LTQuNDM4LDBMMCwwdjY2aDMwMFYweiIvPjwvc3ZnPg==);
                background-position: 50% 0;
                background-size: 100% auto;
            }
        }
    }

    &__item {
        margin-bottom: 26px;

        @include f-last(1) {
            margin-bottom: 0;
        }
    }

    &__info {
        position: relative;
        display: block;
        max-width: 240px;
        z-index: 1;
        @include clearfix;

        @include respond-to('increased') {
            max-width: 100%;
        }
    }

    &__amount {
        position: relative;
        display: block;
        margin-bottom: 30px;
        white-space: nowrap;

        @include respond-to('increased') {
            float: left;
            margin-bottom: 0;
        }

        @include respond-to('intermediary') {
            float: none;
            margin-bottom: 15px;
            text-align: center;
        }

        @include respond-to('medium') {
            margin-bottom: 25px;
        }
    }

    &__amount__label {
        position: relative;
        display: block;
        font-family: $font-family-regular;
        font-size: $font-size-predefault;
        line-height: $line-height-little;
        color: $color-white;
        opacity: .5;

        @include respond-to('increased') {
            display: none;
        }
    }

    &__amount__data {
        position: relative;
        display: block;
        font-family: $font-family-regular;
        font-size: $font-size-largeafter*1.08;
        line-height: $line-height-prelittle;
        color: $color-white;

        span {
            font-size: $font-size-largehuge*1.18;

            @include respond-to('increased') {
                font-size: $font-size-largehuge;
            }
        }
    }

    &__graphics {
        position: relative;
        display: block;
        margin-bottom: 65px;

        @include respond-to('increased') {
            display: none;
        }
    }

    &__submit {
        position: relative;
        display: block;
        width: 240px;

        @include respond-to('increased') {
            float: right;
        }

        @include respond-to('intermediary') {
            float: none;
            margin-left: auto;
            margin-right: auto;
        }

        @include respond-to('medium') {
            width: 100%;
        }

        .formSubmit {
            text-align: center;
        }

        .formSubmit__button {
            width: 94%;
            box-shadow: 0 3px 0 0 rgba($color-mariner-dark, 1);

            &:hover {
                box-shadow: 0 3px 0 0 rgba($color-mariner-light, 1);
            }

            &:active {
                box-shadow: none;
            }

            @include respond-to('medium') {
                width: 100%;
            }
        }
    }

    .formSlider__label,
    .formInput__label,
    .formCheckbox__label,
    .formRadio__title {
        font-family: $font-family-semibold;
        font-size: $font-size-premedium;
    }

    .formSlider {
        padding-right: 94px;

        @include respond-to('increased') {
            padding-right: 0;
        }
    }

    .formSlider__header {
        margin-bottom: 12px;

        @include respond-to('medium') {
            margin-bottom: 33px;
        }
    }

    .formSlider__graphics {
        display: none;

        @include respond-to('increased') {
            display: block;
            margin-top: -12px;
        }

        @include respond-to('intermediary') {
            margin-top: 0;
        }
    }

    .formSlider__label {
        width: auto;
        float: left;
        margin-right: 22px;

        @include respond-to('intermediary') {
            float: none;
        }
    }

    .formSlider__field {
        margin-top: -11px;
        margin-right: 15px;

        @include respond-to('intermediary') {
            margin-top: 0;
        }
    }

    .formRadio {
        padding-right: 55px;

        @include respond-to('increased') {
            padding-right: 0;
        }

        @include respond-to('medium') {
            display: none;
        }
    }

    .formRadio__label {
        padding-right: 0;
    }

    .formRadio__item {
        @include gallery(4);
        margin-bottom: 7px;

        @include respond-to('intermediary') {
            @include gallery(4);
        }
    }

    .formRadio__icon {
        top: 50%;
        margin-top: -8px;
    }

    .formRadio__text {
        height: 25px;
        line-height: 25px;

        img {
            position: relative;
            display: block;
            max-height: 25px;
            top: 50%;
            transform: translateY(-50%);
        }

        svg {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            max-height: 25px;
        }
    }

    .formSlider__action {
        .ui-slider-handle {
            @include respond-to('medium') {
                width: 42px;
                height: 42px;
                top: -21px;
                margin-left: -21px;

                &:before {
                    width: 34px;
                    height: 34px;
                    margin-top: -17px;
                }
            }
        }
    }

    .formInput {
        padding-right: 122px;

        @include respond-to('prebig') {
            padding-right: 0;
        }
    }
}
