$cta: '.cta';

#{$cta} {
    position: relative;
    display: block;
    width: 100%;
    @include sideBarBlock;
    @include blockMargin;

    &__title {
        h2 {
            letter-spacing: 0px;
        }
    }

    &__text {
        margin-top: 5px;

        p {
            margin: 0;
            font-size: $font-size-predefault;
            line-height: $line-height-presmall;
            color: $color-black;
        }
    }

    &__btn {
        margin-top: 15px;

        .btn {
            width: 100%;
        }
    }
}
