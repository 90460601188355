$sideCalculator: '.sideCalculator';

#{$sideCalculator} {
    position: relative;
    display: block;
    @include sideBarBlock;
    @include blockMargin;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;

    &__content {
        position: relative;
        display: block;

        .calculator {
            .calculator__header {
                display: block;
            }

            .calculator__col {
                @include span(12);
                height: auto !important;
                padding-top: 20px;
                padding-left: 20px;
                padding-right: 20px;
                transform: translateZ(0);
            }

            .calculator__col.col_1 {
                padding-bottom: 66px;

                @include respond-to('prebig') {
                    padding-bottom: 20px;
                }

                @include respond-to('small') {
                    padding-bottom: 66px;
                }
            }

            .calculator__col.col_2 {
                border-radius: 0 0 3px 3px;

                &:before {
                    display: block;
                    width: auto;
                    height: 66px;
                    top: auto;
                    right: auto;
                    left: -2px;
                    right: -2px;
                    bottom: 100%;
                    margin-right: 0;
                    margin-bottom: -6px;
                    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIzMDBweCIgaGVpZ2h0PSI2NnB4IiB2aWV3Qm94PSIwIDAgMzAwIDY2IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAzMDAgNjYiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBmaWxsPSIjNEI4N0QyIiBkPSJNMzAwLDBMMTUyLjE5Niw2MC41MjVjLTEuMjI2LDAuNzU3LTMuMjEzLDAuNzU3LTQuNDM4LDBMMCwwdjY2aDMwMFYweiIvPjwvc3ZnPg==);
                    background-position: 50% 100%;
                    background-size: 100% auto;
                    transform: translateZ(0);

                    @include respond-to('prebig') {
                        display: none;
                    }

                    @include respond-to('small') {
                        display: block;
                    }
                }
            }

            .calculator__info {
                max-width: 100%;
                text-align: center;
            }

            .calculator__graphics {
                display: none;
            }

            .calculator__amount {
                float: none;
                margin-bottom: 15px;
            }

            .calculator__submit {
                width: auto;
                max-width: 240px;
                float: none;
                margin-left: auto;
                margin-right: auto;
            }

            .calculator__amount__label {
                display: none;
            }

            .formSlider {
                padding-right: 0;
            }

            .formSlider__header {
                margin-bottom: 20px;
            }

            .formSlider__label {
                width: 100%;
                float: none;
                margin-right: 0;
            }

            .formSlider__field {
                margin-top: 0;
                margin-right: 10px;
            }

            .formSlider__graphics {
                display: block;
            }

            .formSlider__scale__cell.desktop {
                display: none;
            }

            .formSlider__scale__cell.mobile {
                display: inline-block;
            }

            .formRadio {
                display: none;
            }

            .formInput {
                padding-right: 0;
            }
        }
    }


}
