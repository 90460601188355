$formErrorState: '.formErrorState';

#{$formErrorState} {
    .formInput__input {
        border-color: $color-alizarin-crimson !important;
    }

    .formTextarea__textarea {
        border-color: $color-alizarin-crimson !important;
    }
}
