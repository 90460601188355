$seoText: '.seoText';

#{$seoText} {
    position: relative;
    display: block;
    @include blockMargin;
    margin-top: 30px;

    &__wrapper {
        position: relative;
        display: block;
    }

    &__header {
        position: relative;
        display: block;
    }

    &__title {
        position: relative;
        display: block;

        h2 {
            @include blockTitle;
        }
    }

    &__content {
        position: relative;
        display: block;
    }

    &__text {
        position: relative;
        display: block;
        font-size: $font-size-predefault;
        line-height: $line-height-large;

        @include respond-to('prebig') {
            height: 72px;
            overflow: hidden;
        }

        p {
            margin-bottom: .7em;

            @include f-last(1) {
                margin-bottom: 0;
            }
        }
    }

    &__footer {
        position: relative;
        display: none;
        padding-top: 9px;

        @include respond-to('prebig') {
            display: block;
        }
    }

    &__link {
        position: relative;
        display: inline-block;
        vertical-align: top;
        font-size: $font-size-predefault;
        line-height: $line-height-prelittle;
        color: $color-havelock-blue;
        border-bottom: 1px dashed $color-havelock-blue;

        &:hover {
            color: $color-havelock-blue;
            border-color: $color-havelock-blue;
        }
    }

    &__share {
        padding-top: 26px;
    }
}

#{$seoText}.active {
    #{$seoText} {
        &__text {
            height: auto;
        }

        &__link {
            color: $color-sun;
            border-color: $color-sun;
        }
    }
}
